<template>
    <!-- Desktop -->
    <v-navigation-drawer
        mobile-breakpoint="lg"
        width="320"
        color="primary"
        v-model="desktopDrawer"
        floating>
        <div class="tw-flex tw-h-full tw-flex-col tw-justify-between">
            <div class="tw-my-14 tw-flex tw-items-center tw-justify-center 2xl:tw-my-14">
                <v-img
                    width="65"
                    height="65"
                    src="../assets/images/dgrsp.png">
                </v-img>
            </div>
            <v-list
                nav
                class="tw-text-xl tw-text-white">
                <v-list-item
                    v-if="user?.role?.name === 'Admin'"
                    link
                    :to="{ name: 'Users' }"
                    prepend-icon="mdi-account-group"
                    :title="$t('sidebar.users')"></v-list-item>
                <v-list-item
                    link
                    :to="{ name: 'Quizzes' }"
                    prepend-icon="mdi-help-box-multiple"
                    :title="$t('sidebar.quizzes')"></v-list-item>
                <v-list-item
                    link
                    :to="{ name: 'Events' }"
                    prepend-icon="mdi-calendar-multiple"
                    :title="$t('sidebar.events')"></v-list-item>
                <v-list-item
                    v-if="user?.role?.name === 'Tutor' || user?.role?.name === 'Utente'"
                    link
                    :to="{ name: 'Chat' }"
                    prepend-icon="mdi-chat"
                    :title="$t('sidebar.chat')">
                    <template v-slot:append>
                        <div
                            v-if="isLoading == false"
                            class="tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-200 tw-text-sm">
                            <p class="tw-mt-1 tw-text-center">{{ unreadMessages }}</p>
                        </div>
                    </template>
                </v-list-item>
            </v-list>
            <div class="tw-mt-auto tw-flex tw-items-center tw-justify-center tw-gap-x-4 tw-py-3 2xl:tw-py-7">
                <div class="tw-flex tw-items-center tw-justify-center">
                    <div>
                        <router-link :to="{ name: 'Profile' }">
                            <io-img
                                class="tw-rounded-full"
                                format="thumbnail"
                                :media="user?.picture"
                                content-type="Media"
                                width="65"
                                height="65"
                                cover></io-img>
                        </router-link>
                    </div>
                    <router-link :to="{ name: 'Profile' }">
                        <p class="tw-mx-5 tw-max-w-[8.125rem] tw-overflow-hidden tw-overflow-ellipsis tw-text-left tw-text-lg tw-font-semibold tw-text-white">
                            {{ user?.username ? user?.username : user?.email }}
                        </p>
                    </router-link>
                </div>
                <div class="tw-mt-1 tw-flex tw-w-14 tw-justify-center tw-gap-x-4 tw-text-center">
                    <span
                        class="fi fi-pt hover:tw-cursor-pointer"
                        @click="setLanguage('pt')"></span>
                    <span
                        class="fi fi-gb hover:tw-cursor-pointer"
                        @click="setLanguage('en')"></span>
                </div>
            </div>
            <v-list class="!tw-bg-primary-200 tw-text-left tw-text-base !tw-text-white">
                <v-list-item @click="logout()">
                    <div class="tw-ml-12 tw-flex">
                        <v-list-item-icon>
                            <v-icon> mdi-logout </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('sidebar.logout') }}</p>
                    </div>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, computed, onBeforeMount } from 'vue';
    import { useAppStore } from '../store/app';
    import { useDisplay } from 'vuetify';
    import { useAuthStore } from '@/store/auth';
    import { useLanguageStore } from '@/store/language';
    import { useMessageStore } from '../store/message';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { socket } from '@/socket';
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const languageStore = useLanguageStore();
    const $router = useRouter();

    const $t = useI18n().t;
    const i18n = useI18n();

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        languageStore.setLanguage(language);
    };

    const desktopDrawer = ref(true);
    const isOpen = ref(false);

    const display = useDisplay();

    const user = authStore.user;
    const isLoading = ref(true);

    const messageStore = useMessageStore();
    const unreadMessages = ref(0);

    const isLgAndUp = computed(() => {
        const { lgAndUp } = display;
        return lgAndUp.value;
    });

    const emit = defineEmits(['isDesktopDrawer']);

    onBeforeMount(() => {
        appStore.setIsSidebarOpen(false);
        if (isLgAndUp.value) {
            desktopDrawer.value = true;
            emit('isDesktopDrawer', true);
        }
        if (!isLgAndUp.value) {
            desktopDrawer.value = false;
            emit('isDesktopDrawer', false);
        }
    });

    watch(
        () => appStore.isSidebarOpen,
        (value) => {
            isOpen.value = value;
        },
    );

    watch(
        () => isOpen.value,
        (value) => {
            appStore.setIsSidebarOpen(value);
        },
    );

    watch(
        () => isLgAndUp.value,
        (value) => {
            if (value) {
                desktopDrawer.value = true;
                emit('isDesktopDrawer', true);
            } else {
                desktopDrawer.value = false;
                emit('isDesktopDrawer', false);
            }
        },
    );

    async function fetchUnreadMessages() {
        const params = {
            filters: {
                user: {
                    id: {
                        $eq: user.id,
                    },
                },
                wasRead: false,
            },
            populate: ['user.picture'],
        };
        const query = qs.stringify(params);
        await axios.get(`/chat-reads?${query}`).then((response) => {
            messageStore.setUnreadMessages(response.data.meta.pagination.total);
            unreadMessages.value = response.data.meta.pagination.total;
            isLoading.value = false;
        });
    }

    fetchUnreadMessages();

    async function logout() {
        socket.emit('disconnected');
        authStore.$reset();
        $router.push({ name: 'Login' });
    }
</script>
