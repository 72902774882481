<template>
    <v-container class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4 lg:tw-gap-0 lg:tw-p-1">
        <v-row class="tw-mb-6 tw-items-center tw-justify-between lg:tw-hidden">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">{{ $t('quizzes.guests') }}</h1>
            </v-col>
            <v-col class="tw-flex tw-justify-end">
                <router-link :to="{ name: 'QuizzInvite' }">
                    <v-icon class="tw-text-3xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <router-link
            v-for="userQuizz in quizz?.userQuizzes"
            :to="user?.role?.name == 'Admin' ? { name: 'User', params: { id: eventAttendance?.user?.id } } : {}">
            <v-card
                elevation="2"
                class="mx-auto tw-mb-4 tw-h-28 tw-px-0 tw-py-4 sm:tw-px-10"
                :title="userQuizz?.user?.username"
                :subtitle="getSubtitle(userQuizz)">
                <template v-slot:prepend>
                    <v-avatar size="65">
                        <io-img
                            class="2xl:tw-h-16 2xl:tw-w-16"
                            format="thumbnail"
                            :media="userQuizz?.user?.picture"
                            content-type="Media"
                            width="65"
                            height="65"
                            cover></io-img>
                    </v-avatar>
                </template>
                <template v-slot:append>
                    <div class="tw-flex tw-flex-wrap tw-items-center tw-text-xl">
                        <v-icon
                            v-if="userQuizz?.isDone == true && userQuizz?.attempts >= 3"
                            @click.prevent="restartQuizz(userQuizz)"
                            class="tw-mr-3 tw-text-3xl"
                            >mdi-restart</v-icon
                        >
                        <v-icon
                            @click.prevent="onDelete(userQuizz.id)"
                            class="tw-text-3xl tw-text-primary">
                            mdi-minus-circle
                        </v-icon>
                    </div>
                </template>
            </v-card>
        </router-link>
        <v-row class="tw-hidden lg:tw-block">
            <v-col align="center">
                <router-link :to="{ name: 'QuizzInvite' }"><v-icon class="tw-text-3xl tw-text-secondary"> mdi-plus-circle </v-icon> </router-link>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { useRouter, useRoute } from 'vue-router';
    import { ref } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        quizz: ContentType<'Quizz'>[];
    }>();

    const { t } = useI18n();

    const $route = useRoute();
    const $router = useRouter();
    const isLoading = ref(true);

    const fetchUserQuizzes = async () => {
        try {
            const params = {
                filters: {
                    quizz: {
                        id: {
                            $eq: $route.params.id,
                        },
                    },
                },
                populate: ['quizz', 'user.picture'],
            };
            const query = qs.stringify(params);
            await axios
                .get(`/user-quizzes?${query}`)
                .then((response) => {
                    props.quizz.userQuizzes = response.data.data;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        } catch (error) {
            console.error(error);
        }
    };

    fetchUserQuizzes();

    const restartQuizz = async (userQuizz: any) => {
        isLoading.value = true;
        try {
            const params = {
                data: {
                    isDone: null,
                    startedAt: null,
                    attempts: userQuizz.attempts + 1,
                },
            };
            await axios.put(`/user-quizzes/${userQuizz.id}`, params).then(() => {
                fetchUserQuizzes();
            });
        } catch (error) {
            console.error(error);
            isLoading.value = false;
        }
    };

    const onDelete = async (userQuizzesId: number) => {
        isLoading.value = true;
        await axios.delete(`/user-quizzes/${userQuizzesId}`).then(() => {
            isLoading.value = false;
            fetchUserQuizzes();
        });
    };

    //computed getSutitle
    const getSubtitle = (userQuizz: any) => {
        if (userQuizz.isDone) {
            return userQuizz.score + ' ' + t('quizzes.points');
        }
        if (!userQuizz.isDone) {
            if (userQuizz.attempts > 3) return t('quizzes.notAnswered');
        }
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
