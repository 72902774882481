import { ContentType } from '@/contentTypes';
import { defineStore } from 'pinia';
let language: string = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

if (language.includes('pt-PT')) {
    language = 'pt';
} else {
    language = 'en';
}

export const useLanguageStore = defineStore('language', {
    state: () => ({
        language: language,
    }),
    getters: {
        getLanguage(state) {
            return state.language;
        },
    },
    actions: {
        setLanguage(language: string) {
            this.language = language;
        },
    },
    persist: true,
});
