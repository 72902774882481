<template>
    <v-container
        class="!tw-mt-4 tw-flex tw-flex-col tw-gap-4 sm:!tw-mt-10"
        fluid>
        <!-- Header (Desktop) -->
        <v-row class="tw-hidden sm:tw-block">
            <v-col sm="9">
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <router-link
                        to=""
                        @click="goBack">
                        <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                    </router-link>
                    <h1 class="tw-text-left tw-text-3xl tw-font-semibold tw-text-secondary">{{ $t('chat.title') }}</h1>
                </div>
            </v-col>
        </v-row>
        <!-- Header (Mobile) -->
        <v-row class="tw-items-center tw-justify-between sm:tw-hidden">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">{{ $t('chat.title') }}</h1>
            </v-col>
            <v-col> </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-text-field
                    v-model="search"
                    clearable
                    :label="$t('chat.search')"
                    variant="outlined"
                    bg-color="tw-white"></v-text-field>
            </v-col>
        </v-row>
        <!------------>
        <router-link
            v-for="chatRoom of chatRooms"
            to=""
            @click.prevent="joinChatRoom(chatRoom.id)">
            <v-card
                elevation="0"
                class="mx-auto tw-px-0"
                :title="chatRoom.users.find((usr) => usr.id !== user.id)?.username">
                <template v-slot:prepend>
                    <v-avatar size="65">
                        <io-img
                            class="2xl:tw-h-16 2xl:tw-w-16"
                            format="thumbnail"
                            :media="chatRoom.users.find((usr) => usr.id !== user.id)?.picture"
                            content-type="Media"
                            width="65"
                            height="65"
                            cover></io-img>
                    </v-avatar>
                </template>
            </v-card>
        </router-link>
        <div
            v-if="chatRooms.length == 0 && !isLoading"
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('chat.startChatNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <div
        class="text-center"
        v-if="pageCount > 0">
        <v-pagination
            :total-visible="totalVisible"
            class="tw-mb-2"
            color="primary"
            v-model="page"
            :length="pageCount"
            @update:model-value="fetchChatRooms"
            rounded="circle"></v-pagination>
    </div>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { ContentType } from '../../contentTypes';
    import { ref, watch, computed, onBeforeMount } from 'vue';
    import { socket } from '@/socket';
    import { useDisplay } from 'vuetify';

    const useStore = useAuthStore();
    const user = useStore.user;

    const page = ref(1);
    const pageSize = ref(25);
    const pageCount = ref(0);

    const $router = useRouter();

    const search = ref('');
    const chatRooms = ref<ContentType<'ChatRoom'>[]>([]);
    const isLoading = ref(true);

    const display = useDisplay();
    const totalVisible = ref(0);

    const isSmAndDown = computed(() => {
        const { smAndDown } = display;
        return smAndDown.value;
    });

    watch(isSmAndDown, () => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    watch(search, () => {
        fetchChatRooms();
    });

    onBeforeMount(() => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    //get chat rooms
    const fetchChatRooms = async () => {
        const params = {
            filters: {
                users: [user.id],
                chatMessages: {
                    id: {
                        $null: true,
                    },
                },
            },
            pagination: {
                page: page.value,
                pageSize: pageSize.value,
            },
            populate: ['users.picture', 'chatMessages.chatRead', 'chatMessages.user'],
        };
        const query = qs.stringify(params);
        const { data } = await axios.get(`/chat-rooms?${query}`);
        chatRooms.value = data.data;
        pageCount.value = data.meta.pagination.pageCount;

        if (search.value) {
            chatRooms.value = chatRooms.value.filter((chatRoom) => {
                return chatRoom.users.some((usr) => usr.id !== user.id && usr.username.toLowerCase().includes(search.value.toLowerCase()));
            });
        }
        isLoading.value = false;
    };

    fetchChatRooms();

    //join chat room
    const joinChatRoom = async (chatRoomId: number) => {
        socket.emit('join', { user: user.id, room: chatRoomId });
        $router.push({ name: 'Messages', params: { id: chatRoomId } });
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
