<template>
    <v-autocomplete
        bg-color="tw-bg-white"
        variant="outlined"
        ref="autocomplete"
        :loading="isLoading"
        :items="items"
        no-filter
        :model-value="selectedItem"
        v-bind="VAutocompleteProps"
        clearable
        return-object
        @click:clear="onClear"
        @update:search="onSearch"
        @update:model-value="onItemSelected"
        :item-title="itemTitle"
        :item-value="itemValue">
        <template v-slot:append-item>
            <div v-intersect.quiet="onScrollEnd" />
        </template>
    </v-autocomplete>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { isEqual, omit } from 'lodash';
    import { AxiosResponse } from 'axios';
    import { VAutocomplete } from 'vuetify/lib/components/index.mjs';

    const props = defineProps<{
        modelValue: number | null | any;
        fetchItems: ({ page, search, id }: { page?: number; search?: string; id?: number }) => Promise<AxiosResponse> | Promise<any>;
        query?: any;
        itemTitle?: string;
        itemValue?: string;
    }>();

    const VAutocompleteProps = computed(() => omit(props, ['modelValue', 'fetchItems']));

    const itemTitle = computed(() => props.itemTitle || 'name');
    const itemValue = computed(() => props.itemValue || 'id');

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number | null): void;
    }>();

    const selectedItem = ref<any>();
    const items = ref<any[]>([]);
    const isLoading = ref(false);
    const pageCount = ref(0);
    const page = ref(1);
    const search = ref('');
    const timerId = ref<NodeJS.Timeout>();
    const autocomplete = ref<VAutocomplete>();

    async function _fetchItems() {
        isLoading.value = true;
        try {
            const { data } = await props.fetchItems({ page: page.value, search: search.value });
            if (!data.data) items.value = data;
            else if (page.value === 1) items.value = data.data;
            else items.value.push(...data.data);
        } catch (error: any) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    }

    function onScrollEnd(isIntersecting: boolean) {
        if (isIntersecting) {
            if (page.value >= pageCount.value) return;
            page.value++;
            _fetchItems();
        }
    }

    function onClear() {
        selectedItem.value = null;
        search.value = '';
        page.value = 1;
        emit('update:modelValue', null);
    }

    function onSearch(_search: string) {
        if (selectedItem.value) {
            if (!_search || _search == selectedItem.value[itemTitle.value]) return;
        }

        if (timerId.value) clearTimeout(timerId.value);
        timerId.value = setTimeout(() => {
            page.value = 1;
            search.value = _search;
            _fetchItems();
        }, 500);
    }

    function onItemSelected(value: any | null) {
        selectedItem.value = value;
        emit('update:modelValue', value ? value[itemValue.value] : null);
    }

    async function init() {
        if (props.modelValue) {
            const { data } = await props.fetchItems({ id: props.modelValue as number });
            if (!data.data) {
                items.value = data;
                selectedItem.value = data.find((item: { name: string }) => item.name == props.modelValue);
            } else {
                items.value = [data.data];
                selectedItem.value = data.data;
            }
        } else await _fetchItems();
    }

    watch(
        () => props.query,
        (a, b) => {
            if (isEqual(a, b)) return;

            page.value = 1;
            search.value = '';
            items.value = [];
            selectedItem.value = null;
            _fetchItems();
        },
    );

    watch(
        () => props.modelValue,
        (value) => {
            if (value) {
                if (selectedItem.value && value == selectedItem.value[itemValue.value]) return;
                init();
            } else {
                selectedItem.value = null;
                items.value = [];
            }
        },
    );

    init();
</script>

<style scoped></style>
