<template>
    <div v-if="isLoading == false">
        <div class="tw-relative">
            <io-img
                class="lg:tw-h-96"
                format="original"
                :media="question?.quiz?.picture"
                content-type="Media"
                cover></io-img>
            <div
                v-if="question?.quiz?.time !== null && question?.quiz.time !== 0"
                class="tw-absolute tw-bottom-0 tw-left-0 tw-flex tw-h-16 tw-w-full tw-items-center tw-justify-center">
                <div class="tw-mx-auto tw-mb-10 tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-full tw-bg-purple">
                    <p class="tw-text-center tw-text-white">{{ timer }}</p>
                </div>
            </div>
        </div>
        <v-container class="tw-flex tw-h-full tw-flex-col tw-justify-end">
            <v-row class="tw-mt-3 tw-text-lg tw-text-secondary">
                <v-col>
                    <div class="tw-mb-3 tw-flex tw-justify-between">
                        <p>{{ question?.name }}</p>
                        <p
                            v-if="question?.score"
                            class="tw-flex tw-justify-end tw-font-bold">
                            +{{ question?.score }}
                        </p>
                    </div>
                    <p class="tw-font-semibold">{{ question?.description }}</p>
                    <v-row class="tw-mt-3 tw-flex-col tw-text-lg tw-text-secondary">
                        <!-- Cards -->
                        <v-col v-if="question.type == 'Cards'">
                            <v-row>
                                <v-col
                                    v-for="(answer, i) in question.answers"
                                    :key="i"
                                    cols="12">
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(answer.id)"
                                        class="tw-h-auto tw-w-full tw-py-1.5"
                                        :class="{
                                            'bg-red-1000': i === 0,
                                            'bg-blue': i === 1,
                                            'bg-green-1000': i === 2,
                                            'bg-yellow': i === 3,
                                            'bg-purple': i === 4,
                                            'bg-orange': i === 5,
                                            'bg-indigo': i === 6,
                                            'bg-teal': i === 7,
                                            'bg-cyan': i === 8,
                                            'bg-lime': i === 9,
                                            'bg-fuchsia': i === 10,
                                            'bg-violet': i === 11,
                                        }"
                                        size="large">
                                        <p>{{ answer.name }}</p>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- True/False -->
                        <v-col v-if="question.type == 'True/False'">
                            <v-row>
                                <v-col
                                    v-for="(answer, i) in question.answers"
                                    :key="i"
                                    cols="12">
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(answer.id)"
                                        class="tw-h-auto tw-w-full tw-py-1.5"
                                        :class="{ 'bg-red-1000': i === 0, 'bg-blue': i === 1 }"
                                        size="large">
                                        <p>{{ answer.name }}</p>
                                    </v-btn>
                                </v-col>
                            </v-row></v-col
                        >
                        <!-- Open Text -->
                        <v-col v-if="question.type == 'Text'">
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        variant="outlined"
                                        clearable
                                        v-model="openText"
                                        :counter="10"
                                        :label="$t('quizzes.form.description')"
                                        required
                                        hide-details></v-textarea>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(question.answers[0].id)"
                                        type="submit"
                                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                                        height="auto"
                                        size="x-large">
                                        {{ $t('quizzes.next') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Images -->
                        <v-col v-if="question.type == 'Images'">
                            <v-row>
                                <v-col
                                    v-for="(answer, i) in question.answers"
                                    :key="i"
                                    cols="6">
                                    <io-img
                                        :class="{ 'tw-pointer-events-none': answerAlreadyClicked, 'tw-cursor-pointer': !answerAlreadyClicked }"
                                        @click="next(answer.id)"
                                        format="original"
                                        height="95"
                                        :media="answer.media"
                                        content-type="Media"
                                        contain></io-img>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Reflection -->
                        <v-col v-if="question.type == 'Reflection'">
                            <v-row>
                                <v-col cols="12">
                                    <video
                                        v-if="question?.media?.mime.includes('video')"
                                        class="tw-mx-auto tw-h-full tw-w-full"
                                        :key="question?.media?.url"
                                        controls>
                                        <source
                                            :src="API_URL + question?.media?.url"
                                            type="video/mp4" />
                                    </video>
                                    <io-img
                                        v-else
                                        format="original"
                                        class="tw-mx-auto tw-h-60 tw-w-60"
                                        :media="question.media"
                                        content-type="Media"
                                        contain></io-img>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        variant="outlined"
                                        clearable
                                        v-model="openText"
                                        :counter="10"
                                        :label="$t('quizzes.form.description')"
                                        required
                                        hide-details></v-textarea>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(question.answers?.length > 0 ? question.answers[0].id : 0)"
                                        type="submit"
                                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                                        height="auto"
                                        size="x-large">
                                        {{ $t('quizzes.next') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Traffic Light -->
                        <v-col v-if="question.type == 'Traffic Light'">
                            <v-row>
                                <v-col
                                    v-for="(answer, i) in question.answers"
                                    :key="i"
                                    cols="12">
                                    <v-btn
                                        :disabled="lightColor !== '' && lightColor !== answer.color"
                                        @click="setTrafficLightAnswer(answer.id)"
                                        class="tw-h-auto tw-w-full tw-bg-primary tw-py-1.5 tw-text-white"
                                        size="large">
                                        <p>{{ answer.name }}</p>
                                    </v-btn>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="tw-mt-4 tw-flex tw-justify-center">
                                    <div class="traffic-light">
                                        <span
                                            class="tw-cursor-pointer tw-bg-red"
                                            :class="{ 'tw-opacity-100': lightColor === 'red', 'tw-opacity-50': lightColor !== 'red' }"></span>
                                        <span
                                            class="tw-cursor-pointer tw-bg-yellow"
                                            :class="{ 'tw-opacity-100': lightColor === 'yellow', 'tw-opacity-50': lightColor !== 'yellow' }"></span>
                                        <span
                                            class="tw-cursor-pointer tw-bg-green"
                                            :class="{ 'tw-opacity-100': lightColor === 'green', 'tw-opacity-50': lightColor !== 'green' }"></span>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(question.answers.find((answer) => answer.color === lightColor).id)"
                                        type="submit"
                                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                                        height="auto"
                                        size="x-large">
                                        {{ $t('quizzes.next') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Raivometer -->
                        <v-col v-if="question.type == 'Raivometer'">
                            <v-row>
                                <v-col
                                    v-if="question?.media"
                                    cols="12">
                                    <video
                                        v-if="question?.media?.mime.includes('video')"
                                        class="tw-mx-auto tw-h-full tw-w-full"
                                        controls>
                                        <source
                                            :src="API_URL + question?.media?.url"
                                            type="video/mp4" />
                                    </video>
                                    <io-img
                                        v-else
                                        format="original"
                                        class="tw-mx-auto tw-h-60 tw-w-60"
                                        :media="question.media"
                                        content-type="Media"
                                        contain></io-img>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="tw-flex tw-justify-center">
                                    <div class="raivometer">
                                        <div
                                            class="temperature"
                                            :style="{ height: raivometerHeight + '%' }">
                                            <v-icon
                                                v-if="raivometerHeight >= 14 && raivometerHeight < 43"
                                                class="tw-left-9 tw-text-green"
                                                >mdi-emoticon-happy</v-icon
                                            >
                                            <v-icon
                                                v-if="raivometerHeight >= 43 && raivometerHeight < 68"
                                                class="tw-left-9 tw-text-yellow"
                                                >mdi-emoticon-neutral</v-icon
                                            >
                                            <v-icon
                                                v-if="raivometerHeight >= 68 && raivometerHeight <= 100"
                                                class="tw-left-9 tw-text-red"
                                                >mdi-emoticon-angry</v-icon
                                            >
                                        </div>
                                        <div class="graduations"></div>
                                    </div>
                                </v-col>
                                <v-col
                                    class="tw-flex tw-justify-center"
                                    cols="12"
                                    lg="4"
                                    offset-lg="4">
                                    <v-slider
                                        class="custom-v-slider"
                                        color="primary"
                                        thumb-label-color="yellow"
                                        v-model="raivometerHeight"
                                        thumb-label="always">
                                        <template v-slot:thumb-label="{ modelValue }">
                                            {{ sliderThumbLabel }}
                                        </template></v-slider
                                    >
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(question.answers?.length > 0 ? question.answers[0].id : 0)"
                                        type="submit"
                                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                                        height="auto"
                                        size="x-large">
                                        {{ $t('quizzes.next') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Matching -->
                        <v-col v-if="question.type == 'Matching'">
                            <v-row>
                                <v-col
                                    cols="6"
                                    class="tw-flex tw-justify-center">
                                    <v-chip-group
                                        v-model="leftMatching"
                                        class="tw-w-full"
                                        item-value="matching"
                                        multiple>
                                        <v-chip
                                            class="match-chips tw-w-full"
                                            v-for="matching in question?.answers[0]?.matching"
                                            :key="matching.id"
                                            :value="matching.answer"
                                            :selected-class="`${matching.leftColor} tw-text-white`">
                                            {{ matching.answer }}
                                            <v-icon
                                                @click="showMoreInfoLeft(matching)"
                                                class="tw-absolute tw-right-1 tw-top-1 tw-z-10 tw-text-sm tw-text-primary">
                                                mdi-dots-horizontal-circle
                                            </v-icon>
                                            <v-dialog
                                                v-model="matching.showMoreLeft"
                                                width="auto">
                                                <v-card
                                                    class="tw-bg-primary tw-pb-1 tw-text-white"
                                                    :text="matching.answer">
                                                </v-card>
                                            </v-dialog>
                                        </v-chip>
                                    </v-chip-group>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="tw-flex tw-justify-center">
                                    <v-chip-group
                                        v-model="rightMatching"
                                        item-value="matching"
                                        class="tw-w-full"
                                        multiple>
                                        <v-chip
                                            class="match-chips tw-w-full"
                                            v-for="matching in question?.answers[0]?.matching"
                                            :key="matching.id"
                                            :value="matching.matching"
                                            :selected-class="`${matching.rightColor} tw-text-white`">
                                            {{ matching.matching }}
                                            <v-icon
                                                @click="showMoreInfoRight(matching)"
                                                class="tw-absolute tw-right-1 tw-top-1 tw-z-50 tw-text-sm tw-text-primary">
                                                mdi-dots-horizontal-circle
                                            </v-icon>
                                            <v-dialog
                                                v-model="matching.showMoreRight"
                                                width="auto">
                                                <v-card
                                                    class="tw-bg-primary tw-pb-1 tw-text-white"
                                                    :text="matching.matching">
                                                </v-card>
                                            </v-dialog>
                                        </v-chip>
                                    </v-chip-group>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn
                                        :disabled="answerAlreadyClicked"
                                        @click="next(question.answers[0].id)"
                                        type="submit"
                                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                                        height="auto"
                                        size="x-large">
                                        {{ $t('quizzes.next') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>

    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { useAuthStore } from '@/store/auth';
    import moment from 'moment';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const useStore = useAuthStore();
    const me = useStore.user;

    const $route = useRoute();
    const $router = useRouter();
    const pagination = ref({ page: 1, pageSize: 1, total: 0 });

    const API_URL = import.meta.env.VITE_API_URL;

    const question = ref<ContentType<'Question'>[]>([]);
    const isLoading = ref(true);

    //handle
    const answerAlreadyClicked = ref(false);

    //traffic light
    const lightColor = ref('');

    //raivometer
    const raivometerHeight = ref(0);
    const sliderThumbLabel = ref('0%');

    //matching
    const leftMatching = ref([]);
    const rightMatching = ref([]);
    const pairs = ref<ContentType<'Pair'>[]>([]);
    const colors = [
        'tw-bg-red',
        'tw-bg-yellow',
        'tw-bg-green',
        'tw-bg-blue',
        'tw-bg-pink',
        'tw-bg-purple',
        'tw-bg-indigo',
        'tw-bg-teal',
        'tw-bg-cyan',
        'tw-bg-orange',
        'tw-bg-lime',
        'tw-bg-fuchsia',
        'tw-bg-violet',
    ];

    //open text
    let openText = ref('');

    //timer
    let timer = ref('');

    //fetch questions
    const fetchQuestions = async () => {
        const params = {
            filters: {
                quiz: $route.params.id,
            },
            populate: ['quiz.picture', 'quiz.userQuizzes.user', 'answers.userAnswers.user', 'answers.media', 'media'],
            pagination: pagination.value,
        };
        const query = qs.stringify(params);
        await axios.get(`/questions?${query}`).then((response) => {
            question.value = response.data.data[0];
            console.log(question.value);
            if (question?.value?.type == 'Matching') shuffleMatching();
            if (question?.value?.type == 'Traffic Light') shuffleTrafficLight();

            pagination.value.total = response.data.meta.pagination.total;
            if (question.value?.alreadyAnswered == true && pagination.value.page == pagination.value.total) finish();
            else if (question.value?.alreadyAnswered == true) {
                pagination.value.page++;
                fetchQuestions();
            } else {
                if (question.value?.quiz?.time !== null && question.value?.quiz?.time !== 0) {
                    startTimer();
                } else {
                    isLoading.value = false;
                }
            }
            answerAlreadyClicked.value = false;
        });
    };

    fetchQuestions();

    const shuffleMatching = () => {
        // Shuffle the matching answers on the right side
        question.value.answers[0].matching = question.value.answers[0].matching.sort(() => Math.random() - 0.5);

        // Extract the left side answers
        let leftMatching = question.value.answers[0].matching.map((match) => match.answer);

        // Shuffle the left side answers
        leftMatching = leftMatching.sort(() => Math.random() - 0.5);

        // Assign the shuffled left side answers back to the matching array
        question.value.answers[0].matching.forEach((match, index) => {
            match.answer = leftMatching[index];
        });
    };

    const shuffleTrafficLight = () => {
        //add color to answers. index 0 is red, 1 is yellow and 2 is green
        question.value.answers[0].color = 'red';
        question.value.answers[1].color = 'yellow';
        question.value.answers[2].color = 'green';
        question.value.answers = question.value.answers.sort(() => Math.random() - 0.5);
    };

    //start timer
    const startTimer = () => {
        const interval = setInterval(() => {
            const startedAt = moment(question.value.quiz.userQuizzes[0].startedAt);
            const current = moment();
            const diffInSeconds = current.diff(startedAt, 'seconds');
            const timeLeft = Math.max(0, question.value.quiz.time - diffInSeconds / 60);

            if (timeLeft <= 0) {
                clearInterval(interval);
                finish();
            }

            timer.value = moment.utc(timeLeft * 60 * 1000).format('mm:ss');
            isLoading.value = false;
        }, 1000);
    };

    //set traffic light answer
    const setTrafficLightAnswer = async (answerId) => {
        //if the light is already set, do not allow to change it
        if (lightColor.value !== '') return;
        //find the answer
        const answer = question.value.answers.find((answer) => answer.id === answerId);
        if (answer.color == 'red') lightColor.value = 'red';
        if (answer.color == 'yellow') lightColor.value = 'yellow';
        if (answer.color == 'green') lightColor.value = 'green';

        const params = {
            data: {
                user: me.id,
                question: question.value.id,
                answer: answerId == 0 ? null : answerId,
                attempt: question.value.quiz.userQuizzes[0].attempts,
            },
        };

        await axios.post('/user-answers', params);
    };

    //watch slider value, to only allow integer values
    watch(raivometerHeight, (value) => {
        if (value % 1 !== 0) {
            raivometerHeight.value = Math.round(value);
            sliderThumbLabel.value = `${Math.round(value)}%`;
        }
        if (value == 0) sliderThumbLabel.value = '0%';
        if (value == 100) sliderThumbLabel.value = '100%';
    });

    //handle matching (left chips)
    watch(leftMatching, (value) => {
        //remove pairs if the matching is removed
        if (value.length < pairs.value.length) {
            for (let i = 0; i < pairs.value.length; i++) {
                if (!value.find((val) => val == pairs.value[i].answer)) {
                    pairs.value.splice(i, 1);
                    rightMatching.value.splice(i, 1);
                }
            }
            //add new pairs if the matching is added
        } else {
            for (let i = 0; i < value.length; i++) {
                if (value[i] == null) {
                    rightMatching.value[i] = null;
                }
                pairs.value[i] = { answer: value[i], matching: rightMatching?.value[i], color: pairs.value[i]?.color ? pairs.value[i]?.color : null };
                //if the pair does not exist, add it
                if (!pairs.value.find((pair) => pair.answer === value[i])) {
                    pairs.value.push({ answer: value[i], matching: null, color: null });
                    //get a random color that is not already assigned
                    let color = colors[Math.floor(Math.random() * colors.length)];
                    while (pairs.value.find((pair) => pair.color === color)) {
                        color = colors[Math.floor(Math.random() * colors.length)];
                    }
                    //assign the color to the pair
                    pairs.value[i].color = color;
                    let answer = question.value.answers.find((answer) => answer.name === value[i]);
                    answer.leftColor = color;
                    //if the pair already exists, use the color that was already assigned
                } else if (pairs.value.find((pair) => pair.answer === value[i])) {
                    if (pairs.value[i].color == null) {
                        //get a random color that is not already assigned
                        let color = colors[Math.floor(Math.random() * colors.length)];
                        while (pairs.value.find((pair) => pair.color === color)) {
                            color = colors[Math.floor(Math.random() * colors.length)];
                        }
                        pairs.value[i].color = color;
                    }
                    //assign the color to the pair
                    let matching = question.value.answers[0].matching.find((match) => match.answer === value[i]);
                    matching.leftColor = pairs.value.find((pair) => pair.answer === value[i]).color;
                }
            }
        }
    });

    //handle matching (right chips)
    watch(rightMatching, (value) => {
        //remove pairs if the matching is removed
        if (value.length < pairs.value.length) {
            for (let i = 0; i < pairs.value.length; i++) {
                if (!value.find((val) => val == pairs.value[i].matching)) {
                    pairs.value.splice(i, 1);
                    leftMatching.value.splice(i, 1);
                }
            }
            //add new pairs if the matching is added
        } else {
            for (let i = 0; i < value.length; i++) {
                //if the matching is null, set the left matching to null
                if (value[i] == null) {
                    leftMatching.value[i] = null;
                }
                //set the pair
                pairs.value[i] = { answer: leftMatching?.value[i], matching: value[i], color: pairs.value[i]?.color ? pairs.value[i]?.color : null };
                //if the pair does not exist, add it
                if (!pairs.value.find((pair) => pair.matching === value[i])) {
                    pairs.value.push({ answer: null, matching: value[i], color: null });
                    //get a random color that is not already assigned
                    let color = colors[Math.floor(Math.random() * colors.length)];
                    while (pairs.value.find((pair) => pair.color === color)) {
                        color = colors[Math.floor(Math.random() * colors.length)];
                    }
                    //assign the color to the pair
                    pairs.value[i].color = color;
                    let matching = question.value.answers[0].matching.find((match) => match.matching === value[i]);
                    matching.rightColor = color;
                    //if the pair already exists, use the color that was already assigned
                } else if (pairs.value.find((pair) => pair.matching === value[i])) {
                    if (pairs.value[i].color == null) {
                        //get a random color that is not already assigned
                        let color = colors[Math.floor(Math.random() * colors.length)];
                        while (pairs.value.find((pair) => pair.color === color)) {
                            color = colors[Math.floor(Math.random() * colors.length)];
                        }
                        pairs.value[i].color = color;
                    }
                    //assign the color to the pair
                    let matching = question.value.answers[0].matching.find((match) => match.matching === value[i]);
                    matching.rightColor = pairs.value.find((pair) => pair.matching === value[i]).color;
                }
            }
        }
    });

    //handle matching (show more info)
    const showMoreInfoLeft = (matching: any) => {
        matching.showMoreLeft = !matching.showMoreLeft;
    };
    const showMoreInfoRight = (matching: any) => {
        matching.showMoreRight = !matching.showMoreRight;
    };

    //next question
    const next = async (answerId: Number) => {
        answerAlreadyClicked.value = true;
        const params = {
            data: {
                user: me.id,
                question: question.value.id,
                answer: answerId == 0 ? null : answerId,
                attempt: question.value.quiz.userQuizzes[0].attempts,
            },
        };
        if (question.value.type == 'Text' || question.value.type == 'Reflection') {
            params.data.openText = openText.value;
            if (openText.value == '') params.data.openText = t('quizzes.emptyAnswer');
        }
        if (question.value.type == 'Raivometer') {
            params.data.raivometer = sliderThumbLabel.value;
        }

        if (question.value.type == 'Matching') {
            for (let i = 0; i < pairs.value.length; i++) {
                delete pairs.value[i].color;
            }
            params.data.matching = pairs.value;
        }

        if (question.value.type == 'Traffic Light') {
            if (pagination.value.page == pagination.value.total) {
                finish();
                return;
            } else {
                lightColor.value = '';
                pagination.value.page++;
                openText.value = '';
                fetchQuestions();
                return;
            }
        }

        await axios.post('/user-answers', params).then(() => {
            if (pagination.value.page == pagination.value.total) {
                finish();
                return;
            }
            pagination.value.page++;
            openText.value = '';
            fetchQuestions();
        });
    };

    //finish quizz
    const finish = async () => {
        const params = {
            finishQuizz: true,
            populate: ['quizz.questions.answers.userAnswers.user'],
        };
        const query = qs.stringify(params);
        await axios.get(`/user-quizzes/${question.value?.quiz?.userQuizzes[0].id}?${query}`).then((response) => {
            $router.push({ name: 'QuizzDone', params: { doneId: response.data.data.id } });
            isLoading.value = false;
        });
    };
</script>
