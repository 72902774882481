export const useDict = () => {
    async function types(type: string) {
        const typeMappings: { [key: string]: string } = {
            'Escolha Múltipla': 'Cards',
            'Cards': 'Escolha Múltipla',
            'True/False': 'Verdadeiro ou Falso',
            'Verdadeiro ou Falso': 'True/False',
            'Text': 'Texto Livre',
            'Texto Livre': 'Text',
            'Images': 'Imagens',
            'Imagens': 'Images',
            'Reflection': 'Reflexão',
            'Reflexão': 'Reflection',
            'Traffic Light': 'Semáforo',
            'Semáforo': 'Traffic Light',
            'Raivometer': 'Raivómetro',
            'Raivómetro': 'Raivometer',
            'Matching': 'Matching',
        };
        return typeMappings[type] || '';
    }
    return {
        types,
    };
}
