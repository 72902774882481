<template>
    <v-container
        v-if="isLoading == false"
        class="!tw-mt-10 tw-flex tw-h-full tw-flex-col tw-justify-between tw-gap-4">
        <div class="tw-p-10">
            <v-row class="tw-mt-4 tw-flex-col tw-items-center tw-justify-center tw-gap-x-10 sm:tw-flex-row">
                <v-col cols="auto">
                    <io-img
                        class="tw-h-60 tw-w-60 tw-rounded-full sm:tw-h-72 sm:tw-w-72"
                        format="thumbnail"
                        :media="user?.picture"
                        content-type="Media"
                        cover></io-img>
                    <p class="tw-mt-4 tw-text-center tw-text-xl tw-font-semibold tw-text-secondary sm:tw-text-2xl">{{ user?.username }}</p>
                </v-col>
                <v-col
                    class="tw-mt-4 tw-flex tw-flex-col tw-items-center tw-rounded tw-border tw-border-secondary tw-p-8"
                    cols="12"
                    sm="5">
                    <!-- <p class="tw-mb-6 tw-text-3xl">{{ $t('quizzes.score') }}</p> -->
                    <p class="tw-text-3xl tw-font-semibold">{{ userQuizz.scoreMessage }}</p>
                </v-col>
            </v-row>
        </div>
        <router-link
            :to="{ name: 'Quizz', params: { id: $route.params.id } }"
            class="tw-mb-10">
            <v-btn
                type="submit"
                class="bg-primary text-white font-weight-semibold tw-mx-auto tw-h-12 tw-w-full tw-py-1.5 tw-text-base lg:tw-hidden"
                height="auto"
                size="x-large">
                {{ $t('quizzes.conclude') }}
            </v-btn>
        </router-link>
        <div
            v-if="isLoading"
            class="tw-flex tw-h-full tw-items-center tw-justify-center">
            <v-progress-circular
                indeterminate
                color="primary"
                model-value="20"></v-progress-circular>
        </div>
    </v-container>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';

    const authStore = useAuthStore();
    const user = authStore.user;
    const userQuizz = ref<ContentType<'UserQuizz'>>({});
    const isLoading = ref(true);

    const $route = useRoute();

    const fetchUserQuizz = async () => {
        try {
            const params = {
            populate: ['quizz.questions'],
        };
        const query = qs.stringify(params);
            const { data } = await axios.get(`/user-quizzes/${$route.params.doneId}?${query}`);
            userQuizz.value = data.data;
            isLoading.value = false;
        } catch (error) {
            console.error(error);
        }
    };

    fetchUserQuizz();
</script>
