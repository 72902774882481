<template>
    <v-container class="!tw-mt-10 tw-flex tw-h-full tw-flex-col tw-justify-between tw-gap-4">
        <!-- Header -->
        <div>
            <v-row class="tw-items-center tw-justify-between">
                <v-col sm="">
                    <div class="tw-flex tw-items-center tw-gap-x-4">
                        <router-link
                            to=""
                            @click="goBack">
                            <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                        </router-link>
                        <h1 class="tw-hidden tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl lg:tw-block">{{ $t('users.changePassword') }}</h1>
                    </div>
                </v-col>
                <v-col class="lg:tw-hidden">
                    <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">{{ $t('users.changePassword') }}</h1>
                </v-col>
                <v-col class="tw-flex tw-justify-end"
                    ><v-btn
                        class="text-white font-weight-semibold tw-hidden tw-w-7/12 tw-py-1.5 tw-text-base lg:tw-block"
                        :loading="isLoading"
                        type="submit"
                        @click="onSubmit"
                        color="primary"
                        >{{ $t('users.changePassword') }}</v-btn
                    >
                </v-col>
            </v-row>
            <!-- form -->
            <v-row class="tw-mt-4">
                <v-col>
                    <v-form
                        ref="form"
                        @submit.prevent="onSubmit"
                        action="">
                        <v-row>
                            <v-col
                                cols="12"
                                class="tw-mb-1">
                                <v-text-field
                                    clearable
                                    variant="outlined"
                                    bg-color="tw-white"
                                    v-model="oldPassword"
                                    name="old-password"
                                    :rules="[rules.required, (e) => rules.min(e, 6)]"
                                    :type="isOldPasswordVisible ? 'text' : 'password'"
                                    :placeholder="$t('users.oldPassword')"
                                    :append-inner-icon="isOldPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append-inner="isOldPasswordVisible = !isOldPasswordVisible"></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="tw-mb-1">
                                <v-text-field
                                    clearable
                                    variant="outlined"
                                    bg-color="tw-white"
                                    v-model="password"
                                    name="new-password"
                                    :rules="[(e) => rules.comparePassword(e, password), rules.required, (e) => rules.min(e, 6)]"
                                    :type="isPasswordVisible ? 'text' : 'password'"
                                    :placeholder="$t('users.newPassword')"
                                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    clearable
                                    variant="outlined"
                                    bg-color="tw-white"
                                    v-model="passwordConfirmation"
                                    name="new-password"
                                    :rules="[(e) => rules.comparePassword(e, password), rules.required, (e) => rules.min(e, 6)]"
                                    :type="isPasswordVisible ? 'text' : 'password'"
                                    :placeholder="$t('users.passwordConfirmation')"
                                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>
                            </v-col>
                        </v-row>
                        <div
                            v-if="alertMessage"
                            class="tw-mt-4">
                            <v-alert
                                :type="alertType"
                                variant="tonal"
                                density="compact"
                                >{{ alertMessage }}</v-alert
                            >
                        </div>
                    </v-form>
                </v-col>
            </v-row>
        </div>
        <v-row>
            <v-col class="tw-mb-10 tw-flex tw-flex-col tw-justify-end">
                <v-btn
                    class="text-white font-weight-semibold tw-w-full tw-py-1.5 tw-text-base lg:tw-hidden"
                    :loading="isLoading"
                    type="submit"
                    @click="onSubmit"
                    color="primary"
                    >{{ $t('users.changePassword') }}</v-btn
                >
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useI18n } from 'vue-i18n';
    import { useRoute, useRouter } from 'vue-router';
    import { getErrorMessage } from '@/composables/error';

    const $t = useI18n().t;
    const $route = useRoute();
    const $router = useRouter();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);

    const oldPassword = ref('');
    const password = ref('');
    const passwordConfirmation = ref('');
    const isPasswordVisible = ref(false);
    const isOldPasswordVisible = ref(false);
    const alertMessage = ref('');
    const alertType = ref<'success' | 'error'>('success');

    async function onSubmit() {
        if (!form.value) return;

        alertMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await axios.post('/auth/change-password', {
                currentPassword: oldPassword.value,
                password: password.value,
                passwordConfirmation: passwordConfirmation.value,
            });

            alertMessage.value = $t('auth.changePasswordSuccess');
            alertType.value = 'success';
            setTimeout(() => {
                $router.push({ name: 'Profile' });
            }, 2000);
        } catch (error) {
            alertMessage.value = getErrorMessage(error, 'auth.errors');
            alertType.value = 'error';
        } finally {
            isLoading.value = false;
        }
    }

    const goBack = () => {
        $router.go(-1);
    };
</script>

<style scoped></style>
