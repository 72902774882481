<template>
    <!-- Desktop -->
    <v-container class="tw-mb-8 tw-hidden tw-p-1 lg:tw-block">
        <v-expansion-panels
            v-for="question in quizz?.questions"
            class="tw-mt-8"
            elevation="2">
            <v-expansion-panel>
                <v-expansion-panel-title>
                    <template v-slot:actions>
                        <router-link :to="{ name: 'QuizzQuestion', params: { questionId: question?.id } }">
                            <v-icon
                                v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                                @click="isNavigationDrawerOpen = !isNavigationDrawerOpen"
                                class="tw-mr-2"
                                >mdi-pencil-circle</v-icon
                            >
                        </router-link>
                        <v-icon
                            v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                            @click.prevent="onDelete(question?.id)"
                            class="tw-text-red"
                            >mdi-delete</v-icon
                        >
                    </template>
                    <div class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                        <p class="tw-text-base tw-font-light">{{ question?.name }}</p>
                        <p class="tw-text-base">{{ question?.description ? question?.description : '-' }}</p>
                    </div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <quizz-answers :question="question" />
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row
            class="tw-mt-4"
            v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'">
            <v-col align="center">
                <v-icon
                    @click.prevent="addQuestion"
                    class="tw-text-3xl tw-text-secondary">
                    mdi-plus-circle
                </v-icon>
            </v-col>
        </v-row>
        <quizz-question
            v-if="isNavigationDrawerOpen && quizz.questions"
            :isOpen="isNavigationDrawerOpen"
            :quizz="quizz"
            :question="quizz.questions.find((question) => question.id == $route.params.questionId)" />
    </v-container>
    <!-- Mobile -->
    <v-container class="tw-flex tw-flex-col tw-gap-4 lg:tw-hidden">
        <div v-if="$route.name == 'QuizzQuestions'">
            <v-expansion-panels
                v-for="question in quizz?.questions"
                class="tw-mt-8"
                elevation="2">
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <template v-slot:actions>
                            <router-link :to="{ name: 'QuizzQuestion', params: { questionId: question?.id } }">
                                <v-icon
                                    v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                                    @click="isNavigationDrawerOpen = !isNavigationDrawerOpen"
                                    class="tw-mr-2"
                                    >mdi-pencil-circle</v-icon
                                >
                            </router-link>
                            <v-icon
                                v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                                @click.prevent="onDelete(question?.id)"
                                class="tw-text-red"
                                >mdi-delete</v-icon
                            >
                        </template>
                        <div class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                            <p class="tw-text-base tw-font-light">{{ question?.name }}</p>
                            <p class="tw-text-base">{{ question?.description ? question?.description : '-' }}</p>
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <quizz-answers :question="question" />
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-row
                class="tw-mt-1"
                v-if="$route.name == 'QuizzQuestions' && (user?.role?.name == 'Admin' || user?.role?.name == 'Tutor')">
                <v-col align="center">
                    <v-icon
                        @click.prevent="addQuestion"
                        class="tw-text-secondary">
                        mdi-plus-circle
                    </v-icon>
                </v-col>
            </v-row>
        </div>
        <quizz-question
            v-if="quizz.questions && $route.name == 'QuizzQuestion'"
            :quizz="quizz"
            :question="quizz.questions.find((question) => question.id == $route.params.questionId)"
            :isEditingMobile="isEditingMobile" />
    </v-container>
</template>
<script setup lang="ts">
    import { ref, watch, onBeforeMount } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { useRoute, useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { useDict } from '@/composables/useDict';
    import QuizzQuestion from '@/views/Quizzes/QuizzQuestion.vue';
    import QuizzAnswers from '@/views/Quizzes/QuizzAnswers.vue';
    import axios from '@axios';
    import qs from 'qs';

    const authStore = useAuthStore();
    const user = authStore.user;

    const props = defineProps<{
        quizz: ContentType<'Quizz'>[];
        isEditingMobile: boolean;
    }>();

    const $router = useRouter();
    const $route = useRoute();
    const isNavigationDrawerOpen = ref(false);

    onBeforeMount(() => {
        if ($route.name === 'QuizzQuestion') {
            isNavigationDrawerOpen.value = true;
        } else {
            isNavigationDrawerOpen.value = false;
        }
    });

    watch($route, () => {
        if ($route.name == 'QuizzQuestion') isNavigationDrawerOpen.value = true;
        if ($route.name == 'QuizzQuestion' && isNavigationDrawerOpen.value == false) $router.push({ name: 'QuizzQuestions' });
    });

    const fetchQuizz = async () => {
        const params = {
            populate: ['picture', 'questions.answers.media', 'questions.answers', 'questions.media', 'userQuizzes.user.picture', 'createdByUser'],
        };
        const query = qs.stringify(params);
        const { data } = await axios.get(`/quizzes/${$route.params.id}?${query}`);
        props.quizz = data.data;
        props.quizz.questions = data.data.questions;
        props.quizz?.questions?.forEach((question) => {
            useDict()
                .types(question.type)
                .then((response) => {
                    question.type = response;
                });
        });
    };

    const addQuestion = async () => {
        const params = {
            data: {
                quiz: props.quizz?.id,
                name: `Pergunta ${props.quizz.questions.length + 1}`,
                description: '-',
            },
        };
        await axios.post(`/questions`, params).then(() => {
            fetchQuizz();
        });
    };

    const onDelete = async (questionId: string) => {
        await axios.delete(`/questions/${questionId}`).then(() => {
            fetchQuizz();
        });
    };
</script>
