<template>
    <v-row v-if="question && quizz">
        <v-col cols="12">
            <io-img
                format="thumbnail"
                :media="quizz?.picture"
                content-type="Media"
                height="120"
                contain></io-img>
        </v-col>
        <v-col>
            <p>{{ isEditingMobile }}</p>
            <v-form
                ref="form"
                @submit.prevent="onSubmit"
                action=""
                :disabled="!props.isEditingDesktop && !props.isEditingMobile">
                <v-row class="custom-disabled tw-text-lg tw-text-secondary">
                    <!-- Question -->
                    <v-col
                        cols="12"
                        md="12">
                        <p class="tw-mb-2 lg:tw-mb-3">{{ $t('quizzes.form.question') }}</p>
                        <v-text-field
                            :rules="[rules.required]"
                            clearable
                            variant="outlined"
                            bg-color="tw-white"
                            v-model="question.description"
                            :label="$t('quizzes.form.question')"
                            required></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="12">
                        <p class="tw-mb-2">{{ $t('quizzes.form.score') }}</p>
                        <v-text-field
                            clearable
                            variant="outlined"
                            bg-color="tw-white"
                            v-model="question.score"
                            :label="$t('quizzes.form.score')"
                            required></v-text-field>
                    </v-col>
                    <!-- Answers Count -->
                    <v-col
                        cols="12"
                        md="12"
                        v-if="
                            question?.type != 'Verdadeiro ou Falso' && question?.type != 'Texto Livre' && question?.type != 'Reflexão' && question?.type != 'Semáforo' && question?.type != 'Raivómetro'
                        ">
                        <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.answersCount') }}</p>
                        <v-text-field
                            min="1"
                            type="number"
                            :rules="[rules.required]"
                            clearable
                            variant="outlined"
                            bg-color="tw-white"
                            v-model="answersCount"
                            :label="$t('quizzes.form.answersCount')"
                            required></v-text-field>
                    </v-col>
                    <!-- Answer Type -->
                    <v-col
                        cols="12"
                        xs="6">
                        <p class="tw-mb-2 tw-text-base">{{ $t('quizzes.form.answerType') }}</p>
                        <autocomplete-questions-type
                            :rules="[rules.required]"
                            density="compact"
                            v-model="question.type"></autocomplete-questions-type>
                    </v-col>
                    <!-- Answers -->
                    <!-- Cards -->
                    <v-col
                        v-if="question.type == 'Escolha Múltipla' && question.answers.length > 0"
                        cols="12">
                        <p class="tw-mb-2 tw-font-semibold lg:tw-mb-3">{{ $t('quizzes.form.answers') }}</p>
                        <v-row v-for="(n, i) in question?.answers">
                            <v-col
                                cols="9"
                                xs="10"
                                sm="10"
                                md="10"
                                lg="10">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="question.answers[i].name"
                                    :label="$t('quizzes.form.answer') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                            <v-col>
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{
                                        'tw-bg-primary': question.answers[i].isCorrect == true,
                                        'tw-pointer-events-none tw-opacity-40 lg:tw-opacity-60': !props.isEditingDesktop && !props.isEditingMobile,
                                    }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="question.answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- True/False -->
                    <v-col
                        v-if="question.type == 'Verdadeiro ou Falso' && question.answers.length > 0"
                        cols="12">
                        <p class="tw-mb-2 tw-font-semibold">{{ $t('quizzes.form.answers') }}</p>
                        <v-row v-for="(n, i) in question?.answers">
                            <v-col
                                cols="9"
                                xs="10"
                                sm="10"
                                md="10"
                                lg="10">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="question.answers[i].name"
                                    :label="i == 0 ? $t('quizzes.form.true') : $t('quizzes.form.false')"
                                    required></v-text-field>
                            </v-col>
                            <v-col>
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{
                                        'tw-bg-primary': question.answers[i].isCorrect == true,
                                        'tw-pointer-events-none tw-opacity-40 lg:tw-opacity-60': !props.isEditingDesktop && !props.isEditingMobile,
                                    }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="question.answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Open Text -->
                    <!-- Images -->
                    <v-col v-if="question.type == 'Imagens' && question.answers.length > 0">
                        <p class="tw-mb-2 tw-font-semibold">{{ $t('quizzes.form.answers') }}</p>
                        <v-row>
                            <v-col
                                v-for="(n, i) in question?.answers"
                                cols="6"
                                class="tw-relative">
                                <io-img
                                    :rules="[rules.required]"
                                    :class="{ 'tw-opacity-40 lg:tw-opacity-60': !props.isEditingDesktop && !props.isEditingMobile }"
                                    format="original"
                                    height="95"
                                    :media="question?.answers[i].media"
                                    content-type="Media"
                                    contain></io-img>
                                <v-file-input
                                    :loading="isLoadingFile"
                                    :rules="[rules.required]"
                                    v-model="media[i]"
                                    class="custom-label"
                                    density="compact"
                                    prepend-icon=""
                                    variant="underlined"
                                    :label="$t('quizzes.form.selectImage')"
                                    :clearable="false"
                                    accept="image/*"
                                    @update:model-value="onMediaChanged(i)">
                                </v-file-input>
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-absolute tw-right-4 tw-top-4 tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{
                                        'tw-bg-primary': question.answers[i].isCorrect == true,
                                        'tw-pointer-events-none tw-opacity-40 lg:tw-opacity-60': !props.isEditingDesktop && !props.isEditingMobile,
                                    }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="props.question.answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Reflection -->
                    <v-col v-if="question.type == 'Reflexão'">
                        <v-row>
                            <v-col cols="12">
                                <video
                                    v-if="question?.media?.mime?.includes('video')"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    :key="question?.media?.url"
                                    controls>
                                    <source
                                        :src="API_URL + question.media.url"
                                        type="video/mp4" />
                                </video>
                                <io-img
                                    v-else
                                    format="original"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    :media="question.media"
                                    content-type="Media"
                                    cover></io-img>
                                <v-file-input
                                    :loading="isLoadingFile"
                                    class="tw-mt-4"
                                    v-model="media[0]"
                                    density="compact"
                                    variant="underlined"
                                    :label="$t('quizzes.form.loadFile')"
                                    clearable
                                    show-size
                                    :hide-details="true"
                                    @update:model-value="onMediaChanged(0)">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Traffic Light -->
                    <v-col v-if="question.type == 'Semáforo' && question.answers.length > 0">
                        <v-row v-for="(n, i) in question?.answers">
                            <v-col
                                cols="9"
                                xs="10"
                                sm="10"
                                md="10"
                                lg="10">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="question.answers[i].name"
                                    :label="$t('quizzes.form.answer') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                            <v-col>
                                <div
                                    @click="selectCorrectAnswer(i)"
                                    class="tw-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-gray-600"
                                    :class="{
                                        'tw-bg-primary': question.answers[i].isCorrect == true,
                                        'tw-pointer-events-none tw-opacity-40 lg:tw-opacity-60': !props.isEditingDesktop && !props.isEditingMobile,
                                    }">
                                    <v-icon
                                        class="tw-text-white"
                                        v-if="question?.answers[i].isCorrect == true"
                                        >mdi-check-bold</v-icon
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Raivometer -->
                    <v-col v-if="question.type == 'Raivómetro'">
                        <v-row>
                            <v-col cols="12">
                                <video
                                    v-if="question?.media?.mime?.includes('video')"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    controls>
                                    <source
                                        :src="API_URL + question.media.url"
                                        type="video/mp4" />
                                </video>
                                <io-img
                                    v-else
                                    format="original"
                                    class="tw-mx-auto tw-h-60 tw-w-60"
                                    :media="question.media"
                                    content-type="Media"
                                    cover></io-img>
                                <v-file-input
                                    :loading="isLoadingFile"
                                    class="tw-mt-4"
                                    v-model="media[0]"
                                    density="compact"
                                    variant="underlined"
                                    :label="$t('quizzes.form.loadFile')"
                                    clearable
                                    show-size
                                    :hide-details="true"
                                    @update:model-value="onMediaChanged(0)">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Matching -->
                    <v-col
                        v-if="question.type == 'Matching' && question.answers[0]?.matching?.length > 0"
                        cols="12">
                        <p class="tw-mb-2 tw-font-semibold lg:tw-mb-3">{{ $t('quizzes.form.answers') }}</p>
                        <v-row v-for="(n, i) in question?.answers[0]?.matching">
                            <v-col cols="6">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="question.answers[0].matching[i].answer"
                                    :label="$t('quizzes.form.answer') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    :rules="[rules.required]"
                                    clearable
                                    bg-color="tw-bg-white"
                                    v-model="question.answers[0].matching[i].matching"
                                    :label="$t('quizzes.form.matching') + ' ' + (i + 1)"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
    <div
        v-if="isLoading"
        class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import AutocompleteQuestionsType from './AutocompleteQuestionsType.vue';
    import axios from '@axios';
    import { useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { watch, ref, onMounted } from 'vue';
    import { uploadFiles } from '@/composables/upload';
    import { useDict } from '@/composables/useDict';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';

    const $route = useRoute();

    const rules = useRules();
    const form = ref<VForm>();

    const API_URL = import.meta.env.VITE_API_URL;

    const props = defineProps<{
        quizz: ContentType<'Quizz'>[];
        question: ContentType<'Question'>[];
        isEditingDesktop: boolean;
        isEditingMobile: boolean;
    }>();
    const isLoadingFile = ref(false);

    const emit = defineEmits(['update:question']);
    const answers = ref(props.question?.answers);
    const questionType = ref(props?.question?.type);
    const answersCount = ref(props?.question?.type == 'Matching' ? props?.question?.answers[0]?.matching?.length : props?.question?.answers.length);

    const media = ref<File[]>([]);

    const questionChanged = ref(false);

    //watch is editingDesktop and mobile to submit the form
    watch([() => props.isEditingDesktop, () => props.isEditingMobile], (value) => {
        if (!value[0] && !value[1]) {
            onSubmit();
        }
    });

    //watch route (question id) change
    watch(
        () => $route.params.questionId,
        (newValue, oldValue) => {
            if (newValue != oldValue) {
                questionChanged.value = true;
            }
        },
    );
    //watch answer count and answer type at the same time
    watch(
        () => {
            return {
                type: props.question?.type,
                answersCount: answersCount.value,
            };
        },
        ({ type, answersCount }, { type: oldType, answersCount: oldAnswersCount }) => {
            if (type != oldType && answersCount == oldAnswersCount && questionChanged.value == false) {
                if (type == 'Texto Livre' || type == 'Reflexão' || type == 'Raivómetro') {
                    props.question.answersCount = 1;
                    props.question.answers = [
                        {
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                    console.log(props.question.answers);
                }
                if (type == 'Semáforo') {
                    props.question.answersCount = 3;
                    props.question.answers = [
                        { name: 'Vermelho', isCorrect: null, media: null, matching: [] },
                        { name: 'Amarelo', isCorrect: null, media: null, matching: [] },
                        { name: 'Verde', isCorrect: null, media: null, matching: [] },
                    ];
                }
                if (type == 'Verdadeiro ou Falso') {
                    props.question.answersCount = 2;
                    props.question.answers = [
                        { name: 'Verdadeiro', isCorrect: null, media: null, matching: [] },
                        { name: 'Falso', isCorrect: null, media: null, matching: [] },
                    ];
                }
            } else if (type == oldType && answersCount != oldAnswersCount && questionChanged.value == false) {
                if (type == 'Imagens' || type == 'Escolha Múltipla') {
                    props.question.answers = [];
                    for (let i = 0; i < answersCount; i++) {
                        props.question.answers.push({
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        });
                    }
                }
                if (type == 'Verdadeiro ou Falso') {
                    props.question.answers = [];
                    for (let i = 0; i < answersCount; i++) {
                        props.question.answers.push({
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        });
                    }
                }

                if (type == 'Matching') {
                    props.question.answers = [
                        {
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                    for (let i = 0; i < answersCount; i++) {
                        props.question.answers[0].matching.push({
                            answer: '',
                            matching: '',
                        });
                    }
                    answersCount = props.question.answers[0].matching.length;
                }
            } else if (type != oldType && answersCount != oldAnswersCount && questionChanged.value == false) {
                if (type == 'Texto Livre' || type == 'Reflexão' || type == 'Raivómetro') {
                    props.question.answersCount = 1;
                    props.question.answers = [
                        {
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                    console.log(props.question.answers);
                }
                if (type == 'Semáforo') {
                    props.question.answersCount = 3;
                    props.question.answers = [
                        { name: 'Vermelho', isCorrect: null, media: null, matching: [] },
                        { name: 'Amarelo', isCorrect: null, media: null, matching: [] },
                        { name: 'Verde', isCorrect: null, media: null, matching: [] },
                    ];
                }
                if (type == 'Verdadeiro ou Falso') {
                    props.question.answersCount = 2;
                    props.question.answers = [
                        { name: 'Verdadeiro', isCorrect: null, media: null, matching: [] },
                        { name: 'Falso', isCorrect: null, media: null, matching: [] },
                    ];
                }
                if (type == 'Imagens' || type == 'Escolha Múltipla') {
                    props.question.answers = [];
                    for (let i = 0; i < answersCount; i++) {
                        props.question.answers.push({
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        });
                    }
                }
                if (type == 'Matching') {
                    props.question.answers = [
                        {
                            name: '',
                            isCorrect: null,
                            media: null,
                            matching: [],
                        },
                    ];
                    for (let i = 0; i < answersCount; i++) {
                        props.question.answers[0].matching.push({
                            answer: '',
                            matching: '',
                        });
                    }
                    answersCount = props.question.answers[0].matching.length;
                }
            } else if (type != oldType || (answersCount != oldAnswersCount && questionChanged.value == true)) {
                questionChanged.value = false;
            }
        },
    );

    const updateQuestion = async () => {
        //if user doesnt change the question type and answers count, just update the question
        console.log(questionType?.value, props.question?.type, answers?.value.length, props.question?.answers?.length)
        if (questionType?.value == props.question?.type && answers?.value?.length == props.question?.answers?.length) {
            try {
                // update answers
                if (props.question?.type !== '' && props.question?.answers?.length > 0) {
                    console.log(props.question.answers);
                    await Promise.all(
                        props.question.answers.map(async (answer) => {
                            console.log(answer);
                            if (answer?.id) {
                                console.log('sim')
                                const params = {
                                    data: {
                                        ...answer,
                                    },
                                };
                                return axios.put(`/answers/${answer.id}`, params);
                            }
                        }),
                    );
                }
                const { type, media, ...rest } = props.question;
                console.log(props.question.answers)
                const questionParams = {
                    data: {
                        media: props.question?.type == 'Reflexão' || props.question?.type == 'Raivómetro' ? props.question.media : null,
                        type: await useDict().types(props.question.type),
                        ...rest,
                    },
                };
                await axios.put(`/questions/${props.question.id}`, questionParams).then((response) => {
                    emit('update:question', response.data.data);
                });
            } catch (error) {
                console.error(error);
            }
            //if user changes the question type, delete the existing answers and create new ones, then update the question
        } else {
            try {
                console.log(answers.value)
                // delete existing answers
                if (answers?.value.length > 0) {
                    try {
                        await Promise.all(
                            answers.value.map(async (answer) => {
                                if (answer?.id) await axios.delete(`/answers/${answer.id}`);
                            }),
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }
                // create new answers
                let createdAnswersIds = [] as number[];

                if (props.question?.type !== '' && props.question?.answers?.length > 0) {
                    await Promise.all(
                        props.question.answers.map(async (answer) => {
                            const { id, ...rest } = answer;
                            const params = {
                                data: {
                                    ...rest,
                                    question: props.question.id,
                                },
                            };
                            return axios.post(`/answers`, params).then((response) => {
                                createdAnswersIds.push(response.data.data.id);
                            });
                        }),
                    );
                }
                // update the question
                const { type, media, ...rest } = props.question;
                const questionParams = {
                    data: {
                        media: props.question?.type == 'Reflexão' || props.question?.type == 'Raivómetro' ? props.question.media : null,
                        type: await useDict().types(props.question.type),
                        ...rest,
                        answers: createdAnswersIds,
                    },
                };
                await axios.put(`/questions/${props.question.id}`, questionParams).then((response) => {
                    emit('update:question', response.data.data);
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    const selectCorrectAnswer = (index: number) => {
        props.question.answers.forEach((answer: any, i: number) => {
            if (i == index) {
                answer.isCorrect = true;
            } else {
                answer.isCorrect = false;
            }
        });
    };

    const onMediaChanged = async (index: number) => {
        if (media?.value[index].length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: media.value[index],
            field: 'media',
        }).then((response) => {
            isLoadingFile.value = false;
            if (response.data.length > 0) {
                if (props.question.type == 'Reflexão' || props.question.type == 'Raivómetro') props.question.media = response.data[0];
                else props.question.answers[index].media = response.data[0];
            }
        });
    };

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        if (props?.question?.score == null) props.question.score = 0;
        form.value.resetValidation();
        const { valid } = await form.value.validate();
        if (!valid) return;
        try {
            await updateQuestion();
        } catch (error) {
            console.error(error);
        }
    };
</script>
