<!-- usar v-navigation-drawer do lado direito, em mobile vai ficar o ecra todo -->
<template>
    <!-- Desktop -->
    <div class="tw-hidden lg:tw-block">
        <v-navigation-drawer
            v-if="question"
            temporary
            class="tw-hidden tw-h-full lg:tw-block"
            mobile-breakpoint="lg"
            width="400"
            color="white"
            location="right"
            v-model="navigationDrawer"
            floating>
            <div class="tw-flex tw-h-full tw-flex-col tw-justify-between">
                <v-container class="tw-mt-6 tw-px-8">
                    <v-row>
                        <v-col>
                            <p class="tw-text-2xl tw-font-semibold tw-text-primary">{{ question?.name }}</p>
                        </v-col>
                        <v-col class="tw-flex tw-justify-end">
                            <v-icon
                                class="tw-text-4xl tw-text-primary"
                                @click="navigationDrawer = !navigationDrawer">
                                mdi-close
                            </v-icon>
                        </v-col>
                    </v-row>
                    <quizz-question-form
                        :quizz="quizz"
                        :question="question"
                        :isEditingMobile="props.isEditingMobile"
                        :isEditingDesktop="isEditingDesktop" />
                </v-container>
                <v-row
                    class="tw-mb-4"
                    v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'">
                    <v-col class="tw-flex tw-items-end">
                        <v-btn
                            v-if="!isEditingDesktop"
                            type="submit"
                            class="bg-primary text-white font-weight-semibold tw-mx-auto tw-h-9 tw-w-10/12 tw-py-1.5 tw-text-base"
                            height="auto"
                            size="x-large"
                            @click="isEditingDesktop = !isEditingDesktop">
                            {{ $t('quizzes.edit') }}
                        </v-btn>
                        <v-btn
                            v-if="isEditingDesktop"
                            type="submit"
                            class="bg-primary text-white font-weight-semibold tw-mx-auto tw-h-9 tw-w-10/12 tw-py-1.5 tw-text-base"
                            height="auto"
                            size="x-large"
                            @click="isEditingDesktop = !isEditingDesktop">
                            {{ $t('quizzes.confirm') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-navigation-drawer>
    </div>
    <!-- Mobile -->
    <v-responsive v-if="!isDesktop">
        <v-container class="tw-flex tw-flex-col tw-gap-4 lg:tw-hidden">
            <quizz-question-form
                value="questions"
                :quizz="quizz"
                :question="question"
                :isEditingMobile="props.isEditingMobile"
                :isEditingDesktop="isEditingDesktop" />
        </v-container>
    </v-responsive>
</template>
<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { useAuthStore } from '@/store/auth';
    import ioImg from '@/components/ioImg.vue';
    import QuizzQuestionForm from '@/views/Quizzes/QuizzQuestionForm.vue';
    import QuizzAnswers from '@/views/Quizzes/QuizzAnswers.vue';

    const isDesktop = computed(() => window.innerWidth > 1024);

    const authStore = useAuthStore();
    const user = authStore.user;

    const navigationDrawer = ref(true);
    const isEditingDesktop = ref(false);

    const props = defineProps<{
        quizz: ContentType<'Quizz'>[];
        question: ContentType<'Question'>[];
        isOpen: boolean;
        isEditingMobile: boolean;
    }>();

    const emit = defineEmits(['update:isOpen']);
</script>
<style scoped>
    .v-navigation-drawer__scrim {
        background: none;
    }
</style>
<style>
    .v-tabs {
        height: auto !important;
    }
</style>
