<template>
    <v-app-bar
        location="bottom"
        class="lg:tw-hidden">
        <template v-slot:prepend>
            <router-link :to="{ name: 'Events' }">
                <v-icon class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"> mdi-calendar-multiple </v-icon>
            </router-link>
            <router-link :to="{ name: 'Quizzes' }">
                <v-icon class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"> mdi-help-box-multiple </v-icon>
            </router-link>
            <router-link
                v-if="user?.role?.name !== 'Admin'"
                :to="{ name: 'Chat' }">
                <div class="tw-relative">
                    <v-icon
                        class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"
                        style="position: relative">
                        mdi-chat
                    </v-icon>
                    <div
                        v-if="isLoading == false"
                        class="tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-ml-4 tw-flex tw-h-4 tw-w-4 tw-justify-center tw-rounded-full tw-bg-primary-200 tw-text-center xs:tw-h-6 xs:tw-w-6">
                        <p class="tw-flex tw-text-xs tw-text-white xs:tw-mt-1">{{ unreadMessages }}</p>
                    </div>
                </div>
            </router-link>
            <router-link
                v-if="user?.role?.name == 'Admin'"
                :to="{ name: 'Users' }">
                <v-icon class="tw-mr-6 tw-text-2xl 3xs:tw-mr-14 2xs:tw-mr-20 xs:tw-text-4xl sm:tw-mr-40 md:tw-mr-48"> mdi-account-group </v-icon>
            </router-link>
            <router-link :to="{ name: 'Profile' }">
                <v-icon class="tw-text-2xl xs:tw-text-4xl"> mdi-account </v-icon>
            </router-link>
            <!-- <v-app-bar-nav-icon @click="openSidebar"></v-app-bar-nav-icon> -->
        </template>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '../store/auth';
    import { useMessageStore } from '../store/message';
    import { ref } from 'vue';
    import axios from '@axios';
    import qs from 'qs';

    const authStore = useAuthStore();
    const user = authStore.user;

    const appStore = useAppStore();
    const messageStore = useMessageStore();

    const unreadMessages = ref(0);
    const isLoading = ref(true);

    async function fetchUnreadMessages() {
        const params = {
            filters: {
                user: {
                    id: {
                        $eq: user.id,
                    },
                },
                wasRead: false,
            },
            populate: ['user.picture'],
        };
        const query = qs.stringify(params);
        await axios.get(`/chat-reads?${query}`).then((response) => {
            messageStore.setUnreadMessages(response.data.meta.pagination.total);
            unreadMessages.value = response.data.meta.pagination.total;
            isLoading.value = false;
        });
    }

    fetchUnreadMessages();

    function openSidebar() {
        appStore.setIsSidebarOpen(true);
    }
</script>

<style scoped></style>
