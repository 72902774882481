<template>
    <io-autocomplete
        :model-value="itemSelected"
        @update:model-value="onUpdate"
        v-bind="VAutocompleteProps"
        :fetch-items="fetchItems"
        item-value="name"></io-autocomplete>
</template>

<script setup lang="ts">
    import ioAutocomplete from '@/components/ioAutocomplete.vue';
    import { ref } from 'vue';
    import { computed } from 'vue';
    import { omit } from 'lodash';
    import { watch } from 'vue';

    const props = defineProps<{
        modelValue: number | null | any;
    }>();

    const itemSelected = ref<number | null>();

    const VAutocompleteProps = computed(() => omit(props, ['modelValue']));

    const emit = defineEmits<{
        (e: 'update:modelValue', value: number | null): void;
    }>();

    async function fetchItems({ page, search, id }: { page?: number; search?: string; id?: number } = {}) {
        let data = [
            {
                id: 1,
                name: 'Escolha Múltipla',
            },
            {
                id: 2,
                name: 'Verdadeiro ou Falso',
            },
            {
                id: 3,
                name: 'Texto Livre',
            },
            {
                id: 4,
                name: 'Imagens',
            },
            {
                id: 5,
                name: 'Reflexão',
            },
            {
                id: 6,
                name: 'Semáforo',
            },
            {
                id: 7,
                name: 'Raivómetro',
            },
            {
                id: 8,
                name: 'Matching',
            }
        ];
        if (search) data = data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
        if (!search)
            return {
                data,
            };
    }

    function onUpdate(value: number | null) {
        itemSelected.value = value;
        emit('update:modelValue', value);
    }

    watch(
        () => props.modelValue,
        (value) => {
            itemSelected.value = value;
        },
        {
            immediate: true,
        },
    );
</script>

<style scoped></style>
