<template>
    <v-container
        v-if="isLoading == false"
        class="!tw-mt-2 tw-flex tw-flex-col tw-gap-4 lg:!tw-mt-10">
        <v-row class="tw-items-center tw-justify-between">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-3xl">
                    {{ $t('quizzes.form.editQuizz') }}
                </h1>
            </v-col>
            <!-- Edit Quizz (Desktop) -->
            <v-col class="tw-invisible tw-flex tw-justify-end sm:tw-visible">
                <v-btn
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-w-1/2 tw-py-1.5 tw-text-end tw-text-base"
                    height="auto"
                    size="x-large"
                    @click="onSubmit">
                    {{ $t('events.form.submit') }}
                </v-btn></v-col
            >
        </v-row>
        <v-form
            ref="form"
            @submit.prevent="onSubmit"
            action="">
            <v-row>
                <!-- Image -->
                <v-col cols="12">
                    <io-img
                        class="tw-h-60 sm:tw-h-72"
                        format="thumbnail"
                        :media="quizz?.picture"
                        content-type="Media"
                        contain></io-img>
                    <v-file-input
                        :loading="isLoadingFile"
                        v-model="image"
                        class="custom-label tw-mt-4"
                        density="compact"
                        variant="underlined"
                        prepend-icon=""
                        :label="$t('quizzes.form.selectImage')"
                        clearable
                        show-size
                        :hide-details="true"
                        @update:model-value="onImageChanged"
                        accept="image/*">
                    </v-file-input>
                </v-col>
                <!-- Name -->
                <v-col
                    cols="12"
                    md="12">
                    <p class="tw-mb-2">{{ $t('quizzes.form.name') }}</p>
                    <v-text-field
                        :rules="[rules.required]"
                        clearable
                        variant="outlined"
                        bg-color="tw-white"
                        v-model="quizz.name"
                        :label="$t('quizzes.form.name')"
                        required></v-text-field>
                </v-col>
                <!-- Start Date -->
                <v-col
                    v-if="user?.role?.name == 'Tutor'"
                    cols="12"
                    md="6">
                    <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.startDate') }}</p>
                    <v-text-field
                        :rules="[rules.required]"
                        clearable
                        bg-color="tw-bg-white"
                        v-model="quizz.start"
                        :label="$t('quizzes.form.startDate')"
                        required
                        type="date"></v-text-field>
                </v-col>
                <!-- End Date -->
                <v-col
                    v-if="user?.role?.name == 'Tutor'"
                    cols="12"
                    md="6">
                    <p class="tw-mb-2">{{ $t('quizzes.form.endDate') }}</p>
                    <v-text-field
                        :rules="[rules.required]"
                        clearable
                        bg-color="tw-bg-white"
                        v-model="quizz.end"
                        :label="$t('quizzes.form.endDate')"
                        required
                        type="date"></v-text-field>
                </v-col>
                <!-- Time Limit -->
                <v-col
                    cols="12"
                    md="6">
                    <p class="tw-mb-2">{{ $t('quizzes.form.timeLimit') }}</p>
                    <v-text-field
                        min="0"
                        type="number"
                        clearable
                        variant="outlined"
                        bg-color="tw-white"
                        v-model="quizz.time"
                        :label="$t('quizzes.form.timeLimit')"
                        required></v-text-field>
                </v-col>
                <!-- Questions Count -->
                <v-col
                    cols="12"
                    md="6">
                    <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.questionsCount') }}</p>
                    <v-text-field
                        min="0"
                        type="number"
                        :rules="[rules.required]"
                        :disabled="true"
                        variant="outlined"
                        bg-color="tw-white"
                        v-model="questionsCount"
                        :label="$t('quizzes.form.questionsCount')"
                        required></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                            <p class="tw-mb-2">{{ $t('quizzes.form.users') }}</p>
                            <v-select
                                :rules="[rules.required]"
                                item-color="primary"
                                bg-color="tw-bg-white"
                                clearable
                                chips
                                :label="$t('events.form.users')"
                                :items="users"
                                item-title="username"
                                item-value="id"
                                v-model="quizz.users"
                                multiple
                                variant="outlined">
                            </v-select>
                        </v-col> -->
                <!-- Next (Mobile) -->
                <!-- Edit Quizz (Mobile) -->
                <v-btn
                    type="submit"
                    @click="onSubmit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                    height="auto"
                    size="x-large">
                    {{ $t('quizzes.form.submit') }}
                </v-btn>
            </v-row>
        </v-form>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-fixed tw-inset-0 tw-z-10 tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import { ref } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { uploadFiles } from '@/composables/upload';
    import { ContentType } from '@/contentTypes';
    import axios from '@axios';
    import qs from 'qs';
    import ioImg from '@/components/ioImg.vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';

    const rules = useRules();
    const form = ref<VForm>();

    const $router = useRouter();
    const $route = useRoute();
    const image = ref<File[]>([]);
    const quizz = ref<ContentType<'Quizz'>[]>([]);
    const users = ref<ContentType<'User'>[]>([]);
    const isLoading = ref(true);
    const isLoadingFile = ref(false);

    const authStore = useAuthStore();
    const user = authStore.user;

    const questionsCount = ref(0);

    const init = async () => {
        const quizzId = $route.params.id;
        const params = { populate: ['picture', 'questions'] };
        const query = qs.stringify(params);
        axios.get(`/quizzes/${quizzId}?${query}`).then((response) => {
            quizz.value = response.data.data;
            questionsCount.value = quizz.value.questions.length;
            isLoading.value = false;
            console.log(quizz.value);
        });
    };

    const updateQuizz = async () => {
        const params = {
            data: {
                ...quizz.value,
            }
        }
        await axios.put(`/quizzes/${quizz.value.id}`, params).then(() => {
           isLoading.value = false;
        }).finally(() => {
            $router.go(-1);
        });
    };

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            updateQuizz();
        } catch (error) {
            console.error(error);
        }
    };

    const onImageChanged = async () => {
        if (image?.value?.length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: image.value,
            field: 'picture',
        }).then((response) => {
            if (response.data.length > 0) quizz.value.picture = response.data[0];
            isLoadingFile.value = false;

        });
    };

    init();

    const goBack = () => {
        $router.go(-1);
    };
</script>
