<template>
    <!-- Desktop -->
    <v-responsive v-if="isDesktop">
        <v-container
            v-if="isLoading == false"
            class="!tw-mt-10 tw-hidden tw-w-full tw-flex-col tw-gap-4 lg:tw-block">
            <!-- Header -->
            <v-row>
                <v-col sm="8">
                    <div class="tw-flex tw-items-center tw-gap-x-4">
                        <router-link :to="{ name: 'Quizzes' }">
                            <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                        </router-link>
                        <h1 class="tw-text-left tw-text-3xl tw-font-semibold tw-text-secondary">{{ quizz?.name }}</h1>
                    </div>
                </v-col>
                <!-- Edit/Delete Quizz -->
                <v-col
                    v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                    class="tw-text-center"
                    cols="2">
                    <router-link :to="{ name: 'EditQuizz' }">
                        <v-btn
                            v-if="!isEditing"
                            type="submit"
                            class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base"
                            height="auto"
                            size="x-large"
                            @click="isEditing = !isEditing">
                            {{ $t('quizzes.edit') }}
                        </v-btn>
                    </router-link>
                </v-col>
                <v-col
                    v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                    cols="2">
                    <v-btn
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base"
                        height="auto"
                        size="x-large"
                        @click="onDelete">
                        {{ $t('quizzes.delete') }}
                    </v-btn>
                </v-col>
                <!-- Start/Continue Quizz  | Check Score -->
                <v-col
                    class="tw-flex tw-justify-end"
                    v-if="user?.role?.name == 'Utente'">
                    <v-btn
                        v-if="quizz?.userQuizzes[0]?.startedAt == null"
                        @click.prevent="startQuizz"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.start') }}
                    </v-btn>
                    <v-btn
                        v-if="quizz?.userQuizzes[0]?.startedAt !== null && quizz?.userQuizzes[0]?.isDone == false"
                        @click.prevent="continueQuizz"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.continue') }}
                    </v-btn>
                    <v-btn
                        v-if="quizz?.userQuizzes[0]?.isDone == true"
                        @click="$router.push({ name: 'QuizzDone', params: { doneId: quizz?.userQuizzes[0].id } })"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.checkScore') }}
                    </v-btn>
                </v-col>
            </v-row>
            <p v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'" class="tw-mt-4 tw-text-lg tw-text-secondary">{{ $t('quizzes.createdBy') }} {{ quizz?.createdByUser?.username }}</p>
            <!-- Tabs -->
            <v-tabs
                class="tw-mt-8"
                v-model="tab">
                <v-tab
                    :to="{ name: 'Quizz', params: { id: quizz?.id } }"
                    hide-slider
                    rounded="lg"
                    border
                    value="information"
                    class="tw-mr-6 tw-bg-primary-300 tw-text-white">
                    {{ $t('quizzes.information') }}
                </v-tab>
                <v-tab
                    :to="{ name: 'QuizzQuestions' }"
                    hide-slider
                    rounded="lg"
                    border
                    value="questions"
                    class="tw-mr-6 tw-bg-primary-300 tw-text-white"
                    >{{ $t('quizzes.questions') }}</v-tab
                >
                <v-tab
                    v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                    :to="{ name: 'QuizzUsers' }"
                    hide-slider
                    rounded="lg"
                    border
                    class="tw-bg-primary-300 tw-text-white"
                    value="users"
                    >{{ $t('quizzes.guests') }}</v-tab
                >
            </v-tabs>
            <v-window
                v-model="tab"
                :touch="false">
                <v-window-item
                    value="information"
                    transition="none">
                    <quizz-info
                        :quizz="quizz"
                        :isLoading="isLoading"
                        :isEditing="isEditing" />
                </v-window-item>
                <v-window-item
                    value="questions"
                    transition="none">
                    <quizz-questions :quizz="quizz" />
                </v-window-item>
                <v-window-item
                    value="users"
                    transition="none">
                    <quizz-users :quizz="quizz" />
                </v-window-item>
            </v-window>
            <!------------>
        </v-container>
    </v-responsive>
    <!-- Mobile -->
    <v-responsive v-if="!isDesktop"
        ><div
            v-if="isLoading == false"
            class="lg:tw-hidden">
            <!-- Header -->
            <div
                v-if="$route.name !== 'QuizzUsers'"
                :class="{ 'tw-relative': $route.name !== 'QuizzQuestion', '': $route.name === 'QuizzQuestion' }"
                class="lg:tw-hidden">
                <io-img
                    v-if="$route.name !== 'QuizzQuestion' && $route.name !== 'QuizzUsers'"
                    format="thumbnail"
                    :media="quizz?.picture"
                    content-type="Media"
                    height="349"
                    cover></io-img>
                <v-container
                    :class="{ 'tw-absolute tw-left-0 tw-right-0 tw-top-0 ': $route.name !== 'QuizzQuestion', '': $route.name === 'QuizzQuestion' }"
                    class="!tw-mt-10 tw-flex tw-justify-between">
                    <v-row class="tw-items-center tw-justify-between">
                        <v-col>
                            <router-link
                                to=""
                                @click="goBack">
                                <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                            </router-link>
                        </v-col>
                        <v-col v-if="$route.name == 'QuizzQuestion'">
                            <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">
                                {{ quizz?.questions?.find((question) => question.id == $route.params.questionId)?.name }}
                            </h1>
                        </v-col>
                        <v-col>
                            <div
                                v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                                class="tw-flex tw-justify-end tw-gap-x-2">
                                <router-link
                                    :to="{ name: 'EditQuizz' }"
                                    v-if="$route.name !== 'QuizzQuestion' && $route.name !== 'QuizzUsers'">
                                    <v-icon
                                        class="tw-text-3xl tw-text-black"
                                        @click="editQuizzMobile">
                                        {{ isEditingMobile ? 'mdi-check-circle' : 'mdi-pencil-circle' }}
                                    </v-icon>
                                </router-link>
                                <v-icon
                                    v-if="$route.name == 'QuizzQuestion'"
                                    class="tw-text-3xl tw-text-secondary"
                                    @click="editQuestion">
                                    {{ isEditingMobile ? 'mdi-check-circle' : 'mdi-pencil-circle' }}
                                </v-icon>
                                <v-icon
                                    v-if="$route.name !== 'QuizzQuestion' && $route.name !== 'QuizzUsers'"
                                    @click="onDelete"
                                    class="tw-text-3xl tw-text-red">
                                    mdi-delete
                                </v-icon>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <quizz-info
                    v-if="$route.name == 'Quizz'"
                    :quizz="quizz"
                    :isLoading="isLoading"
                    :isEditingMobile="isEditingMobile" />
                <quizz-questions
                    v-else
                    :isEditingMobile="isEditingMobile"
                    :quizz="quizz" />
            </div>
            <div v-if="$route.name == 'QuizzUsers'">
                <quizz-users :quizz="quizz" />
            </div></div
    ></v-responsive>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import QuizzInfo from './QuizzInfo.vue';
    import QuizzQuestions from './QuizzQuestions.vue';
    import QuizzUsers from './QuizzUsers.vue';
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref, computed } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { useRoute, useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import { useDict } from '@/composables/useDict';

    const isDesktop = ref(window.innerWidth > 1024);

    const authStore = useAuthStore();
    const user = authStore.user;

    const $router = useRouter();
    const $route = useRoute();

    const isEditing = ref(false);
    const isEditingMobile = ref(false);

    const quizz = ref<ContentType<'Quizz'>[]>([]);
    const tab = ref('');
    const isLoading = ref(true);

    const fetchQuizz = async () => {
        const params = {
            populate: ['picture', 'questions.answers.media', 'questions.answers', 'questions.media', 'userQuizzes.user.picture', 'createdByUser'],
        };
        const query = qs.stringify(params);
        const { data } = await axios.get(`/quizzes/${$route.params.id}?${query}`);
        quizz.value = data.data;
        //question type (enumeration field)
        quizz?.value?.questions?.forEach((question) => {
            useDict()
                .types(question.type)
                .then((response) => {
                    question.type = response;
                });
        });
        isLoading.value = false;
    };

    fetchQuizz();

    const startQuizz = async () => {
        const params = {
            data: {
                isDone: false,
                startedAt: new Date(),
            },
        };
        await axios.put(`/user-quizzes/${quizz.value.userQuizzes[0].id}`, params).then(() => {
            $router.push({ name: 'QuizzFill', params: { id: quizz.value.id } });
        });
    };

    const continueQuizz = async () => {
        $router.push({ name: 'QuizzFill', params: { id: quizz.value.id } });
    };

    const editQuizzMobile = async () => {
        isEditingMobile.value = !isEditingMobile.value;
    };

    const editQuestion = async () => {
        isEditingMobile.value = !isEditingMobile.value;
    };

    const onDelete = async () => {
        try {
            await axios.delete(`/quizzes/${quizz.value.id}`).then(() => {
                $router.push({ name: 'Quizzes' });
            });
        } catch (error) {
            console.log(error);
        }
    };

    const goBack = () => {
        //if previous route is QuizzDone, go back to Quizzes
        const isPreviousRouteQuizzDone = $router?.options?.history?.state?.back?.toString().includes('done');
        if (isPreviousRouteQuizzDone) {
            $router.push({ name: 'Quizzes' });
        } else $router.go(-1);
    };
</script>
