<template>
    <v-container
        v-if="isLoading == false"
        class="!tw-mt-2 lg:!tw-mt-10 tw-flex tw-flex-col tw-gap-4">
        <v-row
            v-if="currentIndex == 0"
            class="tw-hidden sm:tw-block">
            <!-- Header (Desktop) -->
            <div class="tw-flex">
                <v-col sm="9">
                    <div class="tw-flex tw-items-center tw-gap-x-4">
                        <router-link
                            to=""
                            @click="goBack">
                            <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                        </router-link>
                        <h1 class="tw-text-left tw-text-3xl tw-font-semibold tw-text-secondary">{{ $t('quizzes.form.createQuizz') }}</h1>
                    </div>
                </v-col>
                <v-col class="tw-invisible tw-flex tw-justify-end sm:tw-visible">
                    <v-btn
                        @click.prevent="next"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-w-full tw-py-1.5 tw-text-end tw-text-base"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.form.next') }}
                    </v-btn></v-col
                >
            </div>
            <!------------>
        </v-row>
        <v-row
            v-if="currentIndex == 0"
            class="tw-items-center tw-justify-between sm:tw-hidden">
            <!-- Header (Mobile) -->
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <v-col>
                <h1 class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">{{ $t('quizzes.form.createQuizz') }}</h1>
            </v-col>
            <v-col> </v-col>
            <!------------>
        </v-row>
        <v-row v-if="currentIndex == 0">
            <v-col cols="12">
                <io-img
                    class="tw-h-60 sm:tw-h-72"
                    format="thumbnail"
                    :media="quizz?.picture"
                    content-type="Media"
                    contain></io-img>
                <v-file-input
                    :loading="isLoadingFile"
                    :rules="[rules.required]"
                    v-model="image"
                    class="custom-label tw-mt-4"
                    density="compact"
                    variant="underlined"
                    prepend-icon=""
                    :label="$t('events.form.selectPicture')"
                    clearable
                    show-size
                    :hide-details="true"
                    @update:model-value="onImageChanged"
                    accept="image/*">
                </v-file-input>
            </v-col>
            <v-col>
                <v-form
                    ref="form"
                    @submit.prevent="onSubmit"
                    action="">
                    <v-row>
                        <!-- Name -->
                        <v-col
                            cols="12"
                            md="12">
                            <p class="tw-mb-2">{{ $t('quizzes.form.name') }}</p>
                            <v-text-field
                                :rules="[rules.required]"
                                clearable
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.name"
                                :label="$t('quizzes.form.name')"
                                required></v-text-field>
                        </v-col>
                        <!-- Start Date -->
                        <!-- <v-col
                            cols="12"
                            md="6">
                            <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.startDate') }}</p>
                            <v-text-field
                                :rules="[rules.required]"
                                clearable
                                bg-color="tw-bg-white"
                                v-model="quizz.start"
                                :label="$t('quizzes.form.startDate')"
                                required
                                type="date"></v-text-field>
                        </v-col> -->
                        <!-- End Date -->
                        <!-- <v-col
                            cols="12"
                            md="6">
                            <p class="tw-mb-2">{{ $t('quizzes.form.endDate') }}</p>
                            <v-text-field
                                :rules="[rules.required]"
                                clearable
                                bg-color="tw-bg-white"
                                v-model="quizz.end"
                                :label="$t('quizzes.form.endDate')"
                                required
                                type="date"></v-text-field>
                        </v-col> -->
                        <!-- Time Limit -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-mb-2">{{ $t('quizzes.form.timeLimit') }}</p>
                            <v-text-field
                                min="0"
                                type="number"
                                clearable
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.time"
                                :label="$t('quizzes.form.timeLimit')"
                                required></v-text-field>
                        </v-col>
                        <!-- Questions Count -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.questionsCount') }}</p>
                            <v-text-field
                                min="0"
                                type="number"
                                :rules="[rules.required]"
                                clearable
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.questionsCount"
                                :label="$t('quizzes.form.questionsCount')"
                                required></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12">
                            <p class="tw-mb-2">{{ $t('quizzes.form.users') }}</p>
                            <v-select
                                :rules="[rules.required]"
                                item-color="primary"
                                bg-color="tw-bg-white"
                                clearable
                                chips
                                :label="$t('events.form.users')"
                                :items="users"
                                item-title="username"
                                item-value="id"
                                v-model="quizz.users"
                                multiple
                                variant="outlined">
                            </v-select>
                        </v-col> -->
                    </v-row>
                    <!-- Next (Mobile) -->
                    <v-btn
                        @click.prevent="next"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-8 tw-flex tw-w-full tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 sm:tw-hidden"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.form.next') }}
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
        <create-questions
            v-if="currentIndex > 0"
            :quizz="quizz"
            :currentIndex="currentIndex"
            @next="next"
            @previous="previous" />
    </v-container>
    <div
        v-if="isLoading"
        class="tw-fixed tw-inset-0 tw-z-10 tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { uploadFiles } from '@/composables/upload';
    import { ContentType } from '@/contentTypes';
    import axios from '@axios';
    import qs from 'qs';
    import ioImg from '@/components/ioImg.vue';
    import CreateQuestions from './CreateQuestions.vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';

    const rules = useRules();
    const form = ref<VForm>();

    const $router = useRouter();
    const image = ref<File[]>([]);
    const quizz = ref<ContentType<'Quizz'>[]>([]);
    const users = ref<ContentType<'User'>[]>([]);
    const isLoading = ref(true);
    const isLoadingFile = ref(false);

    quizz.value.questions = [
        {
            name: '',
            description: '',
            score: null,
            answersCount: null,
            type: 'Escolha Múltipla',
            answers: [
                {
                    name: '',
                    isCorrect: null,
                    media: null,
                },
            ],
        },
    ];

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            currentIndex.value += 1;
        } catch (error) {
            console.error(error);
        } finally {
            isLoading.value = false;
        }
    };

    const currentIndex = ref(0);

    const next = () => {
        if (currentIndex.value == 0) onSubmit();
        else currentIndex.value += 1;
    };

    const previous = () => {
        currentIndex.value -= 1;
    };

    const fetchUsers = async () => {
        const params = {
            filters: {
                role: {
                    name: 'Utente',
                },
            },
            pagination: {
                pageSize: 100,
            },
        };
        const query = qs.stringify(params);
        const { data } = await axios.get(`/users?${query}`);
        users.value = data;
        isLoading.value = false;
    };

    fetchUsers();

    const onImageChanged = async () => {
        if (image?.value?.length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: image.value,
            field: 'picture',
        }).then((response) => {
            isLoadingFile.value = false;
            if (response.data.length > 0) quizz.value.picture = response.data[0];
        });
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
