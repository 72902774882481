<template>
    <h1 class="auth-title">{{ $t('auth.welcome') }}</h1>
    <v-form
        ref="form"
        @submit.prevent="onSubmit"
        class="auth-form">
        <v-text-field
            v-model.trim="email"
            class="auth-input"
            :rules="[rules.required, rules.email]"
            type="email"
            name="email"
            :placeholder="$t('fields.email')"></v-text-field>
        <v-text-field
            v-model="password"
            class="auth-input"
            name="current-password"
            :rules="[rules.required]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="$t('fields.password')"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>

        <div v-if="errorMessage">
            <v-alert
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </div>

        <v-btn
            :loading="isLoading"
            type="submit"
            color="primary"
            class="auth-btn"
            >{{ $t('auth.login') }}</v-btn
        >

        <router-link
            class="tw-text-center tw-font-semibold"
            :to="{ name: 'ForgotPassword' }"
            >{{ $t('auth.forgotPassword') }}</router-link
        >
        <router-link
            class="tw-text-center tw-underline"
            :to="{ name: 'ForgotPassword' }"
            >{{ $t('auth.resetPassword') }}</router-link
        >
    </v-form>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import qs from 'qs';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useMessageStore } from '@/store/message';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { getErrorMessage } from '@/composables/error';
    import { socket } from '@/socket';

    const authStore = useAuthStore();
    const messageStore = useMessageStore();
    const $router = useRouter();
    const $t = useI18n().t;

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const isPasswordVisible = ref(false);

    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');

    async function onSubmit() {
        if (!form.value) return;

        errorMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            await axios
                .post('/auth/local', {
                    identifier: email.value,
                    password: password.value,
                })
                .then((response) => {
                    socket.emit('connection');
                    socket.emit('init', { token: response.data.jwt });
                    authStore.setToken(response.data.jwt);
                    authStore.setUser(response.data.user);

                    const params = {
                        filters: {
                            user: {
                                id: {
                                    $eq: response.data.user.id,
                                },
                            },
                            wasRead: false,
                        },
                        populate: ['user.picture'],
                    };
                    const query = qs.stringify(params);
                    axios.get(`/chat-reads?${query}`).then((response) => {
                        messageStore.setUnreadMessages(response.data.meta.pagination.total);
                        if (authStore.user?.role?.name == 'Admin') $router.push({ name: 'Users' });
                        else $router.push({ name: 'Quizzes' });
                    });
                });
        } catch (error) {
            errorMessage.value = getErrorMessage(error, 'auth.errors');
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style></style>
