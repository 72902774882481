<template>
    <!-- Desktop -->
    <v-container
        class="!tw-mt-10 tw-hidden tw-w-full tw-flex-col tw-gap-4 lg:tw-block"
        v-if="isLoading == false">
        <!-- Header -->
        <v-row class="tw-flex tw-justify-between">
            <v-col sm="8">
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <router-link
                        to=""
                        @click="goBack">
                        <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                    </router-link>
                    <h1 class="tw-text-left tw-text-3xl tw-font-semibold tw-text-secondary">{{ event?.name }}</h1>
                </div>
            </v-col>
            <!-- Edit/Delete Event -->
            <v-col
                v-if="showEditButton"
                class="tw-text-center"
                cols="2">
                <router-link :to="{ name: 'EditEvent' }">
                    <v-btn
                        v-if="!isEditing"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.edit') }}
                    </v-btn>
                </router-link>
            </v-col>
            <v-col
                v-if="showDeleteButton"
                cols="2">
                <v-btn
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base"
                    height="auto"
                    size="x-large"
                    @click="onDelete">
                    {{ $t('quizzes.delete') }}
                </v-btn>
            </v-col>
        </v-row>
        <p v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'" class="tw-mt-4 tw-text-lg tw-text-secondary">{{ $t('events.createdBy') }} {{ event?.createdByUser?.username }}</p>
        <!-- Chip isEventFinished -->
        <v-chip
            v-if="isEventFinished"
            class="tw-mt-4 tw-text-base"
            color="primary">
            {{ $t('events.finished') }}
        </v-chip>
        <!------------>
        <v-row class="tw-mt-4">
            <v-col>
                <io-img
                    format="thumbnail"
                    height="400px"
                    :media="event?.picture"
                    content-type="Media"
                    cover></io-img>
            </v-col>
            <v-col>
                <p class="tw-mb-4 tw-text-lg tw-font-semibold tw-text-secondary">
                    {{ $t('events.description') }}
                </p>
                <p
                    :class="{ 'tw-line-clamp-4': readMore == false }"
                    class="tw-mb-1 tw-text-justify tw-text-base tw-text-secondary">
                    {{ event?.description }}
                </p>
                <p
                    class="tw-mb-4 tw-cursor-pointer tw-font-semibold tw-text-primary"
                    @click="readMore = !readMore">
                    {{ readMore ? $t('shared.readLess') : $t('shared.readMore') }}
                </p>
                <div class="tw-mb-3 tw-flex tw-gap-x-4 tw-text-base">
                    <v-icon class="tw-text-primary"> mdi-map-marker </v-icon>
                    <p>{{ event?.location }}</p>
                </div>
                <div class="tw-mb-3 tw-flex tw-gap-x-4 tw-text-base">
                    <v-icon class="tw-text-primary"> mdi-calendar </v-icon>
                    <p>{{ event?.date?.split('-').reverse().join('-') }}</p>
                </div>
                <div class="tw-mb-3 tw-flex tw-gap-x-4 tw-text-base">
                    <v-icon class="tw-text-primary"> mdi-clock-time-eight </v-icon>
                    <p>{{ event?.time?.split(':')[0] + ':' + event?.time?.split(':')[1] }}</p>
                </div>
            </v-col>
        </v-row>
        <v-row
            v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'"
            class="tw-mt-10">
            <v-col cols="9">
                <p class="tw-text-lg tw-font-semibold tw-text-secondary sm:tw-text-left">
                    {{ $t('events.guests') }}
                </p>
            </v-col>
            <v-col class="tw-flex tw-justify-end">
                <v-btn
                    :to="{ name: 'Attendance' }"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-full tw-py-1.5 tw-text-base"
                    height="auto"
                    size="x-large">
                    {{ $t('events.seeMore') }}
                </v-btn>
            </v-col>
        </v-row>
        <router-link
            v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'"
            v-for="guest in event?.eventAttendances"
            :to="user?.role?.name == 'Admin' ? { name: 'User', params: { id: guest?.user?.id } } : {}"
            :class="{ 'tw-pointer-events-none': user?.role?.name !== 'Admin' }">
            <v-card
                class="tw-mt-8"
                elevation="2">
                <div class="tw-flex tw-h-24 tw-justify-center tw-text-secondary">
                    <div class="tw-flex tw-w-9/12 tw-items-center 2xl:tw-w-10/12">
                        <v-avatar size="65">
                            <io-img
                                class="2xl:tw-h-16 2xl:tw-w-16"
                                format="thumbnail"
                                :media="guest?.user?.picture"
                                content-type="Media"
                                width="65"
                                height="65"
                                cover></io-img>
                        </v-avatar>
                        <v-card-title class="tw-text-lg tw-font-semibold"> {{ guest?.user?.username }} </v-card-title>
                    </div>
                    <div class="tw-flex tw-w-32 tw-flex-wrap tw-items-center tw-justify-end tw-text-base">
                        <v-chip
                            color="green"
                            v-if="guest?.status == true">
                            {{ $t('events.present') }}
                        </v-chip>
                        <v-chip
                            color="red"
                            v-if="guest?.status == false">
                            {{ $t('events.missed') }}
                        </v-chip>
                        <v-chip v-if="guest?.status == null">
                            {{ $t('events.toBeConfirmed') }}
                        </v-chip>
                    </div>
                </div>
            </v-card>
        </router-link>
        <!-- Utente has the possibily to confirm the attendance -->
        <v-row
            v-if="user?.role?.name == 'Utente' && event?.eventAttendances.length > 0 && event?.eventAttendances[0]?.status == null && isEventFinished"
            class="tw-mt-4">
            <v-col>
                <p class="tw-mb-2">
                    {{ $t('events.didYouAttend') }} <strong>{{ event?.name }}</strong
                    >?
                </p>
                <div>
                    <v-btn
                        class="bg-green text-white font-weight-semibold tw-mx-auto tw-mr-3 tw-py-1.5 tw-text-base"
                        height="auto"
                        size="x-large"
                        @click="submitAttendance(event?.eventAttendances[0]?.id, 'yes')">
                        {{ $t('events.yes') }}
                    </v-btn>
                    <v-btn
                        class="bg-red text-white font-weight-semibold tw-mx-auto tw-py-1.5 tw-text-base"
                        height="auto"
                        size="x-large"
                        @click="submitAttendance(event?.eventAttendances[0]?.id, 'no')">
                        {{ $t('events.no') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="user?.role?.name == 'Utente' && event?.eventAttendances[0]?.status !== null">
            <v-col>
                <v-chip
                    class="!tw-text-overflow-visible tw-whitespace-pre-wrap !tw-py-5"
                    v-if="event?.eventAttendances[0]?.status == true"
                    color="green"
                    label>
                    <v-icon
                        icon="mdi-check-circle"
                        start></v-icon>
                    <p>
                        {{ $t('events.didAttend') }} <span class="tw-font-semibold">{{ event?.name }}</span
                        >.
                    </p>
                </v-chip>
                <v-chip
                    class="!tw-text-overflow-visible tw-whitespace-pre-wrap !tw-py-5"
                    v-if="event?.eventAttendances[0]?.status == false"
                    color="red"
                    label>
                    <v-icon
                        icon="mdi-close-circle"
                        start></v-icon>
                    <p>
                        {{ $t('events.didNotAttend') }} <span class="tw-font-semibold">{{ event?.name }}</span
                        >.
                    </p>
                </v-chip>
            </v-col>
        </v-row>
        <div
            v-if="event?.eventAttendances.length == 0 && (user?.role?.name == 'Tutor' || user?.role?.name == 'Admin')"
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('events.eventAttendancesNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <!-- Mobile -->
    <div class="tw-relative lg:tw-hidden">
        <!-- Header -->
        <v-col class="tw-h-96 tw-p-0">
            <io-img
                class="tw-h-full"
                format="thumbnail"
                :media="event?.picture"
                content-type="Media"
                cover></io-img>
        </v-col>
        <v-col class="tw-absolute tw-top-10 tw-flex tw-items-center tw-justify-end tw-gap-x-2">
            <v-col>
                <router-link
                    to=""
                    @click="goBack">
                    <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                </router-link>
            </v-col>
            <router-link
                v-if="showEditButton"
                :to="{ name: 'EditEvent' }">
                <v-icon class="tw-text-3xl tw-text-black"> mdi-pencil-circle </v-icon>
            </router-link>
            <v-icon
                v-if="showDeleteButton"
                class="tw-text-3xl tw-text-red"
                @click.prevent="onDelete">
                mdi-delete
            </v-icon>
            <!-- Chip isEventFinished -->
        </v-col>
    </div>
    <v-container
        class="!tw-mt-4 tw-flex tw-flex-col tw-gap-4 lg:tw-hidden"
        v-if="isLoading == false">
        <div class="tw-flex tw-justify-between">
            <h1 class="tw-text-left tw-text-3xl tw-font-semibold tw-text-secondary">{{ event?.name }}</h1>
        </div>
        <p v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'" class="tw-text-lg tw-text-secondary">{{ $t('events.createdBy') }} {{ event?.createdByUser?.username }}</p>
        <v-row>
            <v-col
                cols="8"
                md="3">
                <v-chip
                    v-if="isEventFinished"
                    class="tw-text-base"
                    color="primary">
                    {{ $t('events.finished') }}
                </v-chip>
            </v-col>
        </v-row>

        <!------------>
        <v-row class="tw-mt-4">
            <v-col
                xs="12"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <v-icon class="tw-text-2xl tw-text-primary"> mdi-calendar </v-icon>
                    <div>
                        <p class="tw-font-light">{{ $t('events.form.date') }}</p>
                        <p>{{ event?.date?.split('-').reverse().join('-') }}</p>
                    </div>
                </div></v-col
            >
            <v-col
                xs="12"
                sm="4"
                ><div class="tw-flex tw-gap-x-2">
                    <v-icon class="tw-text-2xl tw-text-primary"> mdi-clock-time-eight </v-icon>
                    <div>
                        <p class="tw-font-light">{{ $t('events.form.time') }}</p>
                        <p>{{ event?.time?.split(':')[0] + ':' + event?.time?.split(':')[1] }}</p>
                    </div>
                </div>
            </v-col>
            <v-col
                xs="12"
                sm="4">
                <div class="tw-flex tw-gap-x-2">
                    <v-icon class="tw-text-2xl tw-text-primary"> mdi-map-marker </v-icon>
                    <div>
                        <p class="tw-font-light">{{ $t('events.form.location') }}</p>
                        <p>{{ event?.location }}</p>
                    </div>
                </div></v-col
            >
        </v-row>
        <div class="tw-mb-2 tw-mt-4 tw-text-secondary">
            <p class="tw-mb-2 tw-text-lg tw-font-semibold">
                {{ $t('events.description') }}
            </p>
            <p
                :class="{ 'tw-line-clamp-4': readMore == false }"
                class="tw-mb-1 tw-text-justify tw-text-base tw-text-secondary">
                {{ event?.description }}
            </p>
            <p
                class="tw-mb-4 tw-cursor-pointer tw-font-semibold tw-text-primary"
                @click="readMore = !readMore">
                {{ readMore ? $t('shared.readLess') : $t('shared.readMore') }}
            </p>
        </div>
        <div
            v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'"
            class="3xs:tw-flex 3xs:tw-justify-between">
            <div>
                <h1 class="tw-text-left tw-font-semibold tw-text-secondary">{{ $t('events.guests') }}</h1>
                <v-row no-gutters>
                    <v-col
                        v-for="(eventAttendance, i) in event?.eventAttendances"
                        :key="i"
                        ><div
                            v-if="i == 7"
                            class="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary tw-text-xs tw-text-white sm:tw-h-8 sm:tw-w-8">
                            + {{ event?.eventAttendances?.length - 7 }}
                        </div>
                        <io-img
                            v-if="i < 7"
                            class="tw-h-6 tw-w-6 tw-rounded-full sm:tw-h-8 sm:tw-w-8"
                            format="thumbnail"
                            :media="eventAttendance?.user?.picture"
                            content-type="Media"
                            cover></io-img>
                    </v-col>
                </v-row>
            </div>
            <v-btn
                :to="{ name: 'Attendance' }"
                type="submit"
                class="bg-primary text-white font-weight-semibold tw-w-22 tw-mt-8 tw-flex tw-items-center tw-justify-center tw-py-1.5 tw-text-base 3xs:tw-mb-2 3xs:tw-mt-3"
                height="auto"
                size="x-small">
                {{ $t('events.seeMore') }}
            </v-btn>
        </div>
        <!-- Utente has the possibily to confirm the attendance -->
        <v-row v-if="user?.role?.name == 'Utente' && event?.eventAttendances.length > 0 && event?.eventAttendances[0]?.status == null && isEventFinished">
            <v-col>
                <p class="tw-mb-2">
                    {{ $t('events.didYouAttend') }} <strong>{{ event?.name }}</strong
                    >?
                </p>
                <div>
                    <v-btn
                        class="bg-green text-white font-weight-semibold tw-mx-auto tw-mr-3 tw-py-1.5 tw-text-base"
                        height="auto"
                        size="x-large"
                        @click="submitAttendance(event?.eventAttendances[0]?.id, 'yes')">
                        {{ $t('events.yes') }}
                    </v-btn>
                    <v-btn
                        class="bg-red text-white font-weight-semibold tw-mx-auto tw-py-1.5 tw-text-base"
                        height="auto"
                        size="x-large"
                        @click="submitAttendance(event?.eventAttendances[0]?.id, 'no')">
                        {{ $t('events.no') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="user?.role?.name == 'Utente' && event?.eventAttendances[0]?.status !== null">
            <v-col>
                <v-chip
                    class="!tw-text-overflow-visible tw-whitespace-pre-wrap !tw-py-5"
                    v-if="event?.eventAttendances[0]?.status == true"
                    color="green"
                    label>
                    <v-icon
                        icon="mdi-check-circle"
                        start></v-icon>
                    <p>
                        {{ $t('events.didAttend') }} <span class="tw-font-semibold">{{ event?.name }}</span
                        >.
                    </p>
                </v-chip>
                <v-chip
                    class="!tw-text-overflow-visible tw-whitespace-pre-wrap !tw-py-5"
                    v-if="event?.eventAttendances[0]?.status == false"
                    color="red"
                    label>
                    <v-icon
                        icon="mdi-close-circle"
                        start></v-icon>
                    <p>
                        {{ $t('events.didNotAttend') }} <span class="tw-font-semibold">{{ event?.name }}</span
                        >.
                    </p>
                </v-chip>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ContentType } from '@/contentTypes';
    import { ref, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/auth';
    import moment from 'moment';

    const useStore = useAuthStore();
    const user = useStore.user;

    const $route = useRoute();
    const $router = useRouter();

    const event = ref<ContentType<'Event'>[]>([]);
    const isLoading = ref(true);
    const isEditing = ref(false);

    const isEventFinished = ref(false);
    const currentDate = moment();

    const readMore = ref(false);

    const fetchEvent = async () => {
        try {
            const params = {
                populate: ['picture', 'eventAttendances.user.picture', 'createdByUser'],
            };
            const query = qs.stringify(params);
            const { data } = await axios.get(`/events/${$route.params.id}?${query}`);
            event.value = data.data;
            //handle event finished
            const eventDate = moment(event.value.date);
            const eventTime = moment(event.value.time, 'HH:mm:ss');
            eventDate.set({
                hour: eventTime.get('hour'),
                minute: eventTime.get('minute'),
                second: eventTime.get('second'),
            });
            if (eventDate.isBefore(currentDate)) {
                isEventFinished.value = true;
            }
            isLoading.value = false;
        } catch (error) {
            console.log(error);
        }
    };

    fetchEvent();

    const onDelete = async () => {
        try {
            await axios.delete(`/events/${$route.params.id}`);
            $router.push({ name: 'Events' });
        } catch (error) {
            console.log(error);
        }
    };

    const submitAttendance = async (id: string, status: string) => {
        try {
            const params = {
                data: {
                    status: status == 'yes' ? true : false,
                },
            };
            await axios.put(`/event-attendances/${id}`, params).then(() => {
                fetchEvent();
            });
        } catch (error) {
            console.log(error);
        }
    };

    const showEditButton = computed(() => {
        if (user?.role?.name == 'Admin' && !isEventFinished.value) return true;
        if (user?.role?.name == 'Tutor' && user?.id == event?.value?.createdByUser?.id && !isEventFinished.value) return true;
    });

    const showDeleteButton = computed(() => {
        if (user?.role?.name == 'Admin') return true;
        if (user?.role?.name == 'Tutor' && user?.id == event?.value?.createdByUser?.id) return true;
    });

    const goBack = () => {
        $router.go(-1);
    };
</script>
