<template>
    <v-container
        v-if="isLoading === false"
        class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4"
        fluid>
        <!-- Header -->
        <v-row>
            <v-col>
                <h1
                    v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                    class="tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">
                    {{ $t('quizzes.title') }}
                </h1>
                <h1
                    v-else
                    class="tw-text-center tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">
                    {{ $t('quizzes.title') }}
                </h1>
            </v-col>
            <!-- Create Quizzes (Mobile) -->
            <v-col
                v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                class="tw-text-end sm:tw-hidden">
                <router-link :to="{ name: 'CreateQuizz' }">
                    <v-icon class="tw-text-3xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col
                v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                sm="10"
                md="11">
                <v-text-field
                    v-model="search"
                    clearable
                    :label="$t('quizzes.search')"
                    variant="outlined"
                    bg-color="tw-white"></v-text-field>
            </v-col>
            <v-col
                v-else
                cols="12">
                <v-text-field
                    v-model="search"
                    clearable
                    :label="$t('quizzes.search')"
                    variant="outlined"
                    bg-color="tw-white"></v-text-field>
            </v-col>
            <!-- Create Quizzes (Desktop) -->
            <v-col
                v-if="user?.role?.name == 'Admin' || user?.role?.name == 'Tutor'"
                align-self="center"
                class="tw-hidden tw-text-end sm:tw-block">
                <router-link :to="{ name: 'CreateQuizz' }">
                    <v-icon class="tw-text-4xl tw-text-secondary"> mdi-plus-circle </v-icon>
                </router-link>
            </v-col>
        </v-row>
        <!------------>
        <card-quizz
            v-if="quizzes.length > 0"
            :quizzes="quizzes" />
        <div
            v-else
            class="tw-mt-4 tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-x-1 tw-font-semibold">
            <p>{{ $t('quizzes.quizzesNotFound') }}</p>
            <v-icon class="tw-text-primary">mdi-alert-circle</v-icon>
        </div>
    </v-container>
    <div
        class="text-center tw-px-10"
        v-if="pageCount > 0">
        <v-pagination
            :total-visible="totalVisible"
            class="tw-mb-2"
            color="primary"
            v-model="page"
            :length="pageCount"
            @update:model-value="fetchQuizzes"
            rounded="circle"></v-pagination>
    </div>

    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import cardQuizz from './CardQuizz.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref, watch, computed, onBeforeMount } from 'vue';
    import { ContentType } from '@/contentTypes';
    import { useAuthStore } from '@/store/auth';
    import { useDisplay } from 'vuetify';

    const authStore = useAuthStore();
    const user = authStore.user;

    const page = ref(1);
    const pageSize = ref(25);
    const pageCount = ref(0);

    const search = ref('');
    const quizzes = ref<ContentType<'Quizz'>[]>([]);
    const isLoading = ref(true);

    const display = useDisplay();
    const totalVisible = ref(0);

    const isSmAndDown = computed(() => {
        const { smAndDown } = display;
        return smAndDown.value;
    });

    watch(isSmAndDown, () => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    watch(search, () => {
        fetchQuizzes();
    });

    onBeforeMount(() => {
        totalVisible.value = isSmAndDown.value ? 3 : 5;
    });

    const fetchQuizzes = async () => {
        const params = {
            filters: {
                $or: [
                    {
                        name: {
                            $containsi: search.value,
                        },
                    },
                    {
                        createdByUser: {
                            username: {
                                $containsi: search.value,
                            },
                        },
                    },
                ],
            },
            pagination: {
                page: page.value,
                pageSize: pageSize.value,
            },
            populate: ['picture', 'questions.answers', 'createdByUser'],
        };
        const query = qs.stringify(params);
        const { data } = await axios.get(`/quizzes?${query}`);
        quizzes.value = data.data;
        pageCount.value = data.meta.pagination.pageCount;
        isLoading.value = false;
    };

    fetchQuizzes();
</script>
