<template>
    <!-- Desktop -->
    <v-responsive v-if="isDesktop">
        <div
            v-if="isLoading == false"
            class="tw-hidden lg:tw-block">
            <v-row class="tw-mt-4 tw-items-center">
                <v-col class="tw-h-96">
                    <io-img
                        format="thumbnail"
                        :media="quizz?.picture"
                        content-type="Media"
                        cover></io-img>
                </v-col>
                <v-col>
                    <v-row>
                        <!-- Name -->
                        <v-col
                            cols="12"
                            md="12">
                            <p class="tw-mb-2">{{ $t('quizzes.form.name') }}</p>
                            <v-text-field
                                disabled
                                :rules="[rules.required]"
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.name"
                                :label="$t('quizzes.form.name')"
                                required></v-text-field>
                        </v-col>
                        <!-- Start Date -->
                        <v-col
                            v-if="user?.role?.name == 'Tutor'"
                            cols="12"
                            md="6">
                            <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.startDate') }}</p>
                            <v-text-field
                                :rules="[rules.required]"
                                type="date"
                                disabled
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.start"
                                :label="$t('quizzes.form.startDate')"
                                required></v-text-field>
                        </v-col>
                        <!-- End Date -->
                        <v-col
                            v-if="user?.role?.name == 'Tutor'"
                            cols="12"
                            md="6">
                            <p class="tw-mb-2">{{ $t('quizzes.form.endDate') }}</p>
                            <v-text-field
                                :rules="[rules.required]"
                                type="date"
                                disabled
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.end"
                                :label="$t('quizzes.form.endDate')"
                                required></v-text-field>
                        </v-col>
                        <!-- Time Limit -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-mb-2">{{ $t('quizzes.form.timeLimit') }}</p>
                            <v-text-field
                                disabled
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="quizz.time"
                                :label="$t('quizzes.form.timeLimit')"
                                required></v-text-field>
                        </v-col>
                        <!-- Questions Count -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-mb-2 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">{{ $t('quizzes.form.questionsCount') }}</p>
                            <v-text-field
                                :rules="[rules.required]"
                                disabled
                                variant="outlined"
                                bg-color="tw-white"
                                v-model="questionsCount"
                                :label="$t('quizzes.form.questionsCount')"
                                required></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-responsive>
    <!-- Mobile -->
    <v-responsive v-if="!isDesktop">
        <v-container
            v-if="isLoading == false"
            class="!tw-mt-4 tw-flex tw-flex-col tw-gap-4 lg:tw-hidden">
            <v-row>
                <v-col
                    cols="12"
                    class="tw-flex tw-justify-center sm:tw-justify-start">
                    <h1 class="tw-text-3xl tw-font-semibold tw-text-secondary">
                        {{ quizz?.name }}
                    </h1>
                </v-col>
                <v-col
                    v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'"
                    cols="12"
                    class="tw-flex tw-justify-center sm:tw-justify-start">
                    <p class="tw-text-lg tw-text-secondary">{{ $t('quizzes.createdBy') }} {{ quizz?.createdByUser?.username }}</p>
                </v-col>
            </v-row>
            <v-row class="tw-mb-2 tw-mt-4">
                <!-- Start Date -->
                <v-col
                    v-if="user?.role?.name == 'Utente' || user?.role?.name == 'Tutor'"
                    cols="12"
                    sm="4"
                    class="tw-flex tw-justify-center sm:tw-justify-start"
                    ><div class="tw-flex tw-gap-x-2">
                        <v-icon class="tw-text-2xl tw-text-primary"> mdi-calendar-start </v-icon>
                        <div>
                            <p class="tw-font-light">{{ $t('quizzes.form.startDate') }}</p>
                            <p>{{ new Date(quizz.start).toLocaleDateString('en-GB') }}</p>
                        </div>
                    </div>
                </v-col>
                <!-- End Date -->
                <v-col
                    v-if="user?.role?.name == 'Utente' || user?.role?.name == 'Tutor'"
                    cols="12"
                    sm="4"
                    class="tw-flex tw-justify-center"
                    ><div class="tw-flex tw-gap-x-2">
                        <v-icon class="tw-text-2xl tw-text-primary"> mdi-calendar-end </v-icon>
                        <div>
                            <p class="tw-font-light">{{ $t('quizzes.form.endDate') }}</p>
                            <p>{{ new Date(quizz.end).toLocaleDateString('en-GB') }}</p>
                        </div>
                    </div>
                </v-col>
                <!-- Time Limit -->
                <v-col
                    class="tw-flex tw-justify-center"
                    cols="12"
                    sm="4">
                    <div class="tw-flex tw-gap-x-2">
                        <v-icon class="tw-text-2xl tw-text-primary"> mdi-timer </v-icon>
                        <div>
                            <p class="tw-font-light">{{ $t('quizzes.form.timeLimit') }}</p>
                            <p>{{ quizz?.time ? quizz?.time : $t('quizzes.noTimeLimit') }}</p>
                        </div>
                    </div></v-col
                >
            </v-row>
            <!-- Recipients -->
            <div
                v-if="user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'"
                class="3xs:tw-flex 3xs:tw-justify-between">
                <div>
                    <h1 class="tw-text-left tw-font-semibold tw-text-secondary">{{ $t('quizzes.recipients') }}</h1>
                    <v-row no-gutters>
                        <v-col
                            v-for="(userQuizz, i) in quizz?.userQuizzes"
                            :key="i"
                            ><div
                                v-if="i == 7"
                                class="tw-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary tw-text-xs tw-text-white sm:tw-h-8 sm:tw-w-8">
                                + {{ quizz?.userQuizzes?.length - 7 }}
                            </div>
                            <io-img
                                v-if="i < 7"
                                class="tw-h-6 tw-w-6 tw-rounded-full sm:tw-h-8 sm:tw-w-8"
                                format="thumbnail"
                                :media="userQuizz?.user?.picture"
                                content-type="Media"
                                cover></io-img>
                        </v-col>
                    </v-row>
                </div>
                <span
                    @click="$router.push({ name: 'QuizzUsers' })"
                    class="tw-flex tw-flex-col tw-justify-end tw-text-primary hover:tw-cursor-pointer">
                    {{ $t('quizzes.seeMore') }}
                </span>
            </div>
            <!-- Start Quizz (Utente) -->
            <v-btn
                v-if="user?.role?.name == 'Utente' && quizz?.userQuizzes[0]?.startedAt == null"
                @click.prevent="startQuizz"
                type="submit"
                class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                height="auto"
                size="x-large">
                {{ $t('quizzes.start') }}
            </v-btn>
            <!-- Continue Quizz (Utente) -->
            <v-btn
                v-if="user?.role?.name == 'Utente' && quizz?.userQuizzes[0]?.startedAt !== null && quizz?.userQuizzes[0]?.isDone == false"
                @click.prevent="continueQuizz"
                type="submit"
                class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-4 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                height="auto"
                size="x-large">
                {{ $t('quizzes.continue') }}
            </v-btn>
            <!-- Finished Quizz (Utente) -->
            <div
                class="tw-p-3"
                v-if="(user?.role?.name == 'Utente' && quizz?.userQuizzes[0]?.isDone == true) || user?.role?.name == 'Tutor' || user?.role?.name == 'Admin'">
                <v-row
                    v-if="user?.role?.name == 'Utente'"
                    class="tw-rounded tw-border tw-border-secondary">
                    <v-col class="tw-text-sm tw-font-semibold">
                        <p>{{ $t('quizzes.finished') }}</p></v-col
                    >
                    <v-col
                        cols="12"
                        class="tw-text-xl tw-font-bold">
                        {{ quizz?.userQuizzes[0]?.scoreMessage }}
                    </v-col>
                </v-row>
                <!-- See Questions (Admin/Tutor/Utente) -->
                <v-row>
                    <v-btn
                        @click="$router.push({ name: 'QuizzQuestions' })"
                        type="submit"
                        class="bg-primary text-white font-weight-semibold tw-mx-auto tw-mt-6 tw-h-12 tw-w-full tw-py-1.5 tw-text-base focus:tw-bg-secondary"
                        height="auto"
                        size="x-large">
                        {{ $t('quizzes.seeQuestions') }}
                    </v-btn>
                </v-row>
            </div>
        </v-container></v-responsive
    >
</template>
<script setup lang="ts">
    import ioImg from '@/components/ioImg.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ContentType } from '@/contentTypes';
    import { ref, watch, computed } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';

    const isDesktop = computed(() => window.innerWidth > 1024);

    const rules = useRules();
    const form = ref<VForm>();

    const authStore = useAuthStore();
    const user = authStore.user;

    const $router = useRouter();

    const props = defineProps<{
        quizz: ContentType<'Quizz'>[];
        isLoading: boolean;
        isEditing: boolean;
        isEditingMobile: boolean;
    }>();

    const questionsCount = props.quizz?.questions?.length || props.quizz?.questionsCount;

    //watch isEditing and update quizz
    watch(
        () => props.isEditing,
        () => {
            if (!props.isEditing) {
                onSubmit();
            }
        },
    );

    watch(
        () => props.isEditingMobile,
        () => {
            if (!props.isEditingMobile) {
                onSubmit();
            }
        },
    );

    const updateQuizz = async () => {
        const params = {
            data: {
                ...props.quizz,
            },
        };
        const { data } = await axios.put(`/quizzes/${props.quizz.id}`, params);
        props.quizz = data.data;
    };

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        updateQuizz();
    };

    const startQuizz = async () => {
        const params = {
            data: {
                isDone: false,
                startedAt: new Date(),
            },
        };
        await axios.put(`/user-quizzes/${props.quizz.userQuizzes[0].id}`, params).then(() => {
            $router.push({ name: 'QuizzFill', params: { id: props.quizz.id } });
        });
    };

    const continueQuizz = async () => {
        $router.push({ name: 'QuizzFill', params: { id: props.quizz.id } });
    };
</script>
