<template>
    <v-container
        class="!tw-mt-10 tw-flex tw-flex-col tw-gap-4"
        v-if="isLoading == false">
        <!-- Header -->
        <v-row>
            <v-col
                class="overflow-hidden"
                sm="8">
                <div class="tw-flex tw-items-center tw-gap-x-4">
                    <router-link
                        to=""
                        @click="goBack">
                        <v-icon class="tw-text-3xl tw-text-primary"> mdi-arrow-left </v-icon>
                    </router-link>
                    <h1 class="tw-text-ellipsis tw-whitespace-nowrap tw-overflow-hidden tw-text-2xl tw-font-semibold tw-text-secondary sm:tw-text-left sm:tw-text-3xl">{{ user?.username }}</h1>
                </div>
            </v-col>
            <!-- Edit User (Desktop) -->
            <v-col
                cols="2"
                class="tw-hidden tw-text-center sm:tw-block">
                <v-btn
                    v-if="!isEditing"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large"
                    @click="isEditing = !isEditing">
                    {{ $t('users.edit') }}
                </v-btn>
                <v-btn
                    v-if="isEditing"
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large"
                    @click="isEditing = !isEditing">
                    {{ $t('users.confirm') }}
                </v-btn>
            </v-col>
            <v-col
                cols="2"
                class="tw-hidden tw-text-center sm:tw-block">
                <v-btn
                    type="submit"
                    class="bg-primary text-white font-weight-semibold tw-mx-auto tw-w-1/2 tw-py-1.5 tw-text-base sm:tw-w-full"
                    height="auto"
                    size="x-large"
                    @click="onDelete">
                    {{ $t('users.delete') }}
                </v-btn>
            </v-col>
            <!-- Edit User (Mobile) -->
            <v-col class="tw-flex tw-justify-end tw-gap-x-2 sm:tw-hidden">
                <v-icon
                    @click="isEditing = !isEditing"
                    class="tw-text-3xl tw-text-secondary">
                    {{ isEditing ? 'mdi-check-circle' : 'mdi-pencil-circle' }}
                </v-icon>
                <v-icon
                    class="tw-text-3xl tw-text-red"
                    @click="onDelete">
                    mdi-delete
                </v-icon>
            </v-col>
        </v-row>
        <!------------>
        <v-form
            ref="form"
            @submit.prevent="onSubmit"
            action=""
            :disabled="!isEditing">
            <v-row class="tw-mt-8 tw-flex-col tw-items-center tw-gap-x-10 sm:tw-flex-row">
                <v-col cols="auto">
                    <io-img
                        class="tw-h-60 tw-w-60 tw-rounded-full sm:tw-h-72 sm:tw-w-72"
                        format="thumbnail"
                        :media="user?.picture"
                        content-type="Media"
                        cover></io-img>
                    <v-file-input
                        :loading="isLoadingFile"
                        v-model="image"
                        class="custom-label tw-mt-4 tw-w-60 sm:tw-w-72"
                        density="compact"
                        variant="underlined"
                        prepend-icon=""
                        :label="$t('users.changeProfilePicture')"
                        clearable
                        show-size
                        :hide-details="true"
                        @update:model-value="onImageChanged"
                        accept="image/*">
                    </v-file-input>
                </v-col>
                <v-col
                    cols="12"
                    sm="5">
                    <p class="tw-mb-4">{{ $t('users.role') }}</p>
                    <autocomplete-roles
                        :rules="[rules.required]"
                        density="compact"
                        v-model="user.role.name"></autocomplete-roles>
                    <p class="tw-mb-4">{{ $t('users.email') }}</p>
                    <v-text-field
                        v-model="user.email"
                        :rules="[rules.email]"
                        clearable
                        :label="$t('users.email')"
                        variant="outlined"
                        bg-color="tw-white"
                        class="tw-mb-4"></v-text-field>
                    <p
                        v-if="user?.role?.name == 'Tutor'"
                        class="tw-mb-4">
                        {{ $t('users.users') }}
                    </p>
                    <p
                        v-if="user?.role?.name == 'Utente'"
                        class="tw-mb-4">
                        {{ $t('users.tutor') }}
                    </p>
                    <v-select
                        v-if="user?.role?.name == 'Tutor'"
                        bg-color="tw-white"
                        clearable
                        chips
                        item-color="primary"
                        item-title="username"
                        item-value="id"
                        :label="$t('users.users')"
                        :items="users"
                        v-model="user.children"
                        multiple
                        variant="outlined">
                    </v-select>
                    <v-select
                        v-if="user?.role?.name == 'Utente'"
                        bg-color="tw-white"
                        clearable
                        chips
                        item-color="primary"
                        item-title="username"
                        item-value="id"
                        :label="$t('users.tutor')"
                        :items="users"
                        v-model="user.parent"
                        multiple
                        variant="outlined">
                    </v-select>
                </v-col>
            </v-row>
        </v-form>
        <p
            v-if="user?.role?.name !== 'Admin'"
            class="tw-mt-10 tw-text-lg tw-font-semibold tw-text-secondary sm:tw-text-left">
            {{ user?.role?.name == 'Tutor' ? $t('users.users') : $t('users.tutor') }}
        </p>
        <card-user
            v-if="user?.role?.name !== 'Admin' && (children.length > 0 || parent.length > 0)"
            :users="children.length > 0 ? children : parent" />
    </v-container>
    <div
        v-if="isLoading"
        class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>
<script setup lang="ts">
    import cardUser from './CardUser.vue';
    import ioImg from '@/components/ioImg.vue';
    import AutocompleteRoles from '@/views/Users/AutocompleteRoles.vue';
    import axios from '@axios';
    import qs from 'qs';
    import { ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { ContentType } from '@/contentTypes';
    import { uploadFiles } from '@/composables/upload';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import useRules from '@/composables/rules';

    const rules = useRules();
    const form = ref<VForm>();

    const $route = useRoute();
    const $router = useRouter();
    const user = ref<ContentType<'User'> | null>(null);
    const children = ref<ContentType<'User'>[]>([]);
    const parent = ref<ContentType<'User'>[]>([]);
    const users = ref<ContentType<'User'>[]>([]);
    const isLoading = ref(true);
    const isEditing = ref(false);

    const isLoadingFile = ref(false);
    const image = ref<File[]>([]);

    watch(isEditing, (value) => {
        if (!value) {
            onSubmit();
        }
    });

    watch(
        () => $route.params,
        (to: any, from: any) => {
            if (to.id !== from.id) {
                isEditing.value = false;
                fetchUser();
            }
        },
    );

    const onSubmit = async () => {
        if (!form.value) return;
        form.value.validate();

        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            await updateUser();
        } catch (error) {
            console.log(error);
        }
    };

    const onDelete = async () => {
        try {
            await axios.delete(`/users/${$route.params.id}`);
            $router.push('/users');
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUser = async () => {
        try {
            const params = {
                populate: ['role', 'picture', 'children.role', 'children.picture', 'parent.role', 'parent.picture'],
            };
            const query = qs.stringify(params);
            await axios.get(`/users/${$route.params.id}?${query}`).then(({ data }) => {
                user.value = data;
                console.log(user.value);
                children.value = user.value?.children;
                parent.value = user?.value?.parent ? [user.value?.parent] : [];

                fetchUsers();
            });
        } catch (error) {
            console.log(error);
        }
    };

    fetchUser();

    const fetchUsers = async () => {
        try {
            const params = {
                filters: {
                    role: {
                        name: user.value?.role?.name == 'Tutor' ? 'Utente' : 'Tutor',
                    },
                    id: {
                        $ne: user.value?.id,
                    },
                },
                populate: ['parent', 'role', 'picture'],
            };
            const query = qs.stringify(params);
            const { data } = await axios.get(`/users?${query}`);
            users.value = data;
            isLoading.value = false;
        } catch (error) {
            console.log(error);
        }
    };

    const onImageChanged = async () => {
        if (image?.value?.length == 0) return;
        isLoadingFile.value = true;
        await uploadFiles({
            files: image.value,
            refId: user?.value?.id,
            field: 'picture',
        }).then((response) => {
            isLoadingFile.value = false;
            if (response.data.length > 0) user.value.picture = response.data[0];
        });
    };

    const updateUser = async () => {
        try {
            await axios
                .put(`/users/${$route.params.id}`, {
                    data: {
                        ...user.value,
                        role: user?.value?.role?.name == 'Admin' ? 3 : user?.value?.role?.name == 'Utente' ? 5 : 4,
                        email: user?.value?.email,
                    },
                })
                .then(() => {
                    fetchUser();
                });
        } catch (error) {
            console.log(error);
        }
    };

    const goBack = () => {
        $router.go(-1);
    };
</script>
